import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendAverageOpeningTime } from "../../../../types/AmazonSuspendedApplicantReports";

const SuspendTypeBasedAvarageOpeningTimeOfSuspendReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  // const [dateLabels, setDateLabels] = useState<any[]>([]);
  // const [chartSeries, setChartSeries] = useState<any[]>([]);
  // const [chartHeight, setChartHeight] = useState<number>(200);
  // const tableRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const handleResize = () => {
  //     if (window.innerWidth > 1600 && tableRef.current) {
  //       const tableColHeight = tableRef.current.getBoundingClientRect().height;
  //       setChartHeight(Math.max(200, tableColHeight - 30));
  //     } else {
  //       setChartHeight(250);
  //     }
  //   };

  //   handleResize();
  //   window.addEventListener("resize", handleResize);
  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  //   // eslint-disable-next-line
  // }, [dataSource]);

  const previousMonth = dayjs(dateRange[1]).subtract(1, "month").format("MMMM");
  const twoMonthsAgo = dayjs(dateRange[1]).subtract(2, "month").format("MMMM");

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const formattedStartDate = dayjs(dateRange[0]).format(
        "YYYY-MM-DDT00:00:00"
      );
      const formattedEndDate = dayjs(dateRange[1]).format(
        "YYYY-MM-DDT23:59:59"
      );
      getSuspendTypeBasedAvarageOpeningTimeOfSuspendReport(
        formattedStartDate,
        formattedEndDate
      );
    }
  }, [dateRange]);

  const getSuspendTypeBasedAvarageOpeningTimeOfSuspendReport = (
    startDate: string,
    endDate: string
  ) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getSuspendAverageOpeningTime(
      startDate,
      endDate
    )
      .then((response) => {
        setDataSource(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<SuspendAverageOpeningTime> = [
    {
      title: "Suspend Type",
      dataIndex: "suspendType",
      key: "suspendType",
      fixed: "left",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => (a.suspendType).localeCompare(b.suspendType),
      defaultSortOrder: "ascend",
      render: (suspendType: any) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          title={suspendType}
        >
          <b>{suspendType}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Opened`,
      dataIndex: "twoMonthOpenedCount",
      key: "twoMonthOpenedCount",
      width: 180,
      align: "center",
      sorter: (a, b) => a.twoMonthOpenedCount - b.twoMonthOpenedCount,
      render: (twoMonthOpenedCount: number) => (
        <div>
          <b>{twoMonthOpenedCount !== undefined ? twoMonthOpenedCount : "0"}</b>
        </div>
      ),
    },
    {
      title: `${twoMonthsAgo} Average Opening Time (Days)`,
      dataIndex: "twoMonthAverageOpeningTime",
      key: "twoMonthAverageOpeningTime",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.twoMonthAverageOpeningTime - b.twoMonthAverageOpeningTime,
      render: (twoMonthAverageOpeningTime: number) => (
        <div>
          <b>
            {twoMonthAverageOpeningTime !== undefined
              ? twoMonthAverageOpeningTime
              : "0"}
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Opened`,
      dataIndex: "previousMonthOpenedCount",
      key: "previousMonthOpenedCount",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.previousMonthOpenedCount - b.previousMonthOpenedCount,
      render: (previousMonthOpenedCount: number) => (
        <div>
          <b>
            {previousMonthOpenedCount !== undefined
              ? previousMonthOpenedCount
              : "0"}
          </b>
        </div>
      ),
    },
    {
      title: `${previousMonth} Average Opening Time (Days)`,
      dataIndex: "previousMonthAverageOpeningTime",
      key: "previousMonthAverageOpeningTime",
      width: 180,
      align: "center",
      sorter: (a, b) =>
        a.previousMonthAverageOpeningTime - b.previousMonthAverageOpeningTime,
      render: (previousMonthAverageOpeningTime: number) => (
        <div>
          <b>
            {previousMonthAverageOpeningTime !== undefined
              ? previousMonthAverageOpeningTime
              : "0"}
          </b>
        </div>
      ),
    },
  ];
  

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          {/* <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col> */}
          <Col xs={24} xl={24}>
            <Table
              rowKey={(record) => record?.suspendType}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(pageData) => {
                let totalTwoMonthsAgoOpened = 0;
                let totalTwoMonthsAgoAverageOpeningTime = 0;
                let totalPreviousMonthOpened = 0;
                let totalPreviousMonthAverageOpeningTime = 0;

                pageData.forEach(
                  ({
                    twoMonthOpenedCount,
                    twoMonthAverageOpeningTime,
                    previousMonthOpenedCount,
                    previousMonthAverageOpeningTime,
                  }) => {
                    totalTwoMonthsAgoOpened += twoMonthOpenedCount || 0;
                    totalTwoMonthsAgoAverageOpeningTime +=
                      twoMonthAverageOpeningTime || 0;
                    totalPreviousMonthOpened += previousMonthOpenedCount || 0;
                    totalPreviousMonthAverageOpeningTime +=
                      previousMonthAverageOpeningTime || 0;
                  }
                );

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        align="left"
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={1}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalTwoMonthsAgoOpened}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={2}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalTwoMonthsAgoAverageOpeningTime.toFixed(2)}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={3}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalPreviousMonthOpened}</b>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell
                        index={4}
                        align="center"
                        className="summary-cell"
                      >
                        <b>{totalPreviousMonthAverageOpeningTime.toFixed(2)}</b>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendTypeBasedAvarageOpeningTimeOfSuspendReport;
