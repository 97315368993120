import { Tabs } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export const ReportsRouter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const roleName = localStorage.getItem("roleName");

  const onTabChange = (key: string) => {
    navigate(key);
  };

  const dynamicLabel = (prefix: string, label: string) => {
    return roleName === "ADMIN" ? `${prefix} ${label}` : label;
  };

  const acmTabs = [
    {
      key: "/reports/acm-reports/summary-report",
      label: dynamicLabel("ACM", "Summary Report"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/call-history",
      label: dynamicLabel("ACM", "Call History"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/status",
      label: dynamicLabel("ACM", "Status"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/current-packages",
      label: dynamicLabel("ACM", "Current Package"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/earnings-report",
      label: dynamicLabel("ACM", "Earnings Report"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/sales-log-report",
      label: dynamicLabel("ACM", "Sales Log Report"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/package-of-interest-for-acm",
      label: dynamicLabel("ACM", "Package Of Interest"),
      children: <></>,
    },
    {
      key: "/reports/acm-reports/current-package-of-interest-for-acm",
      label: dynamicLabel("ACM", "Current Package Of Interest"),
      children: <></>,
    },      
  ];

  const stmTabs = [
    {
      key: "/reports/stm-reports/summary-report",
      label: dynamicLabel("STM", "Summary Report"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/learner-status",
      label: dynamicLabel("STM", "Learner Status"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/retention-status",
      label: dynamicLabel("STM", "Retention Status"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/software-suspended-status",
      label: dynamicLabel("STM", "Software Suspended Status"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/education",
      label: dynamicLabel("STM", "Education"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/success-team",
      label: dynamicLabel("STM", "Success Team"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/call-history",
      label: dynamicLabel("STM", "Call History"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/affiliate",
      label: dynamicLabel("STM", "Affiliate"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/earnings-report",
      label: dynamicLabel("STM", "Earnings Report"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/sales-log-report",
      label: dynamicLabel("STM", "Sales Log Report"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/package-of-interest-for-stm",
      label: dynamicLabel("STM", "Package Of Interest"),
      children: <></>,
    },
    {
      key: "/reports/stm-reports/old-package-of-interest-for-stm",
      label: dynamicLabel("STM", "Old Package Of Interest"),
      children: <></>,
    }, 
  ];

  const hi5AcmTabs = [
    {
      key: "/reports/hi5-acm-reports/summary-report",
      label: dynamicLabel("Hi5 ACM", "Summary Report"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/call-history",
      label: dynamicLabel("Hi5 ACM", "Call History"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/status",
      label: dynamicLabel("Hi5 ACM", "Status"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/current-packages",
      label: dynamicLabel("Hi5 ACM", "Current Package"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/earnings-report",
      label: dynamicLabel("Hi5 ACM", "Earnings Report"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/sales-log-report",
      label: dynamicLabel("Hi5 ACM", "Sales Log Report"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/package-of-interest-for-hi5-acm",
      label: dynamicLabel("Hi5 ACM", "Package Of Interest"),
      children: <></>,
    },
    {
      key: "/reports/hi5-acm-reports/current-package-of-interest-for-hi5-acm",
      label: dynamicLabel("Hi5 ACM", "Current Package Of Interest"),
      children: <></>,
    },   
    // {
    //   key: "/reports/hi5-acm-reports/lessons",
    //   label: dynamicLabel("Hi5 ACM", "Lessons"),
    //   children: <></>,
    // },    
  ];

  const ctmTabs = [
    {
      key: "/reports/ctm-reports/summary-report",
      label: dynamicLabel("CTM", "Summary Report"),
      children: <></>,
    },
    {
      key: "/reports/ctm-reports/call-history",
      label: dynamicLabel("CTM", "Call History"),
      children: <></>,
    },
    {
      key: "/reports/ctm-reports/earnings-report",
      label: dynamicLabel("CTM", "Earnings Report"),
      children: <></>,
    },
    {
      key: "/reports/ctm-reports/sales-log-report",
      label: dynamicLabel("CTM", "Sales Log Report"),
      children: <></>,
    },
  ];

  const suspendtmTabs = [
    {
      key: "/reports/suspendtm-reports/amazon-suspended-applicant-reports",
      label: dynamicLabel("Suspend TM", "Amazon Suspended Applicant Reports"),
      children: <></>,
    },
    {
      key: "/reports/suspendtm-reports/amazon-suspended-applicant-status-report",
      label: dynamicLabel("Suspend TM", "Amazon Suspended Applicant Status Report"),
      children: <></>,
    },
    {
      key: "/reports/suspendtm-reports/number-of-violations-paid-report",
      label: dynamicLabel("Suspend TM", "Number of Violations Paid Report"),
      children: <></>,
    },
  ];

  const mastmTabs = [
    {
      key: "/reports/mastm-reports/summary-report",
      label: dynamicLabel("MAS TM", "Summary Reports"),
      children: <></>,
    },
    {
      key: "/reports/mastm-reports/earnings-report",
      label: dynamicLabel("MAS TM", "Earnings Reports"),
      children: <></>,
    },
  ];

  const accountManagementtmTabs = [
    {
      key: "/reports/account-management-tm-reports/profit-and-loss-report",
      label: dynamicLabel("Account Management TM", "Profit and Loss Reports"),
      children: <></>,
    },
  ];

  const marketingtmTabs = [
    {
      key: "/reports/marketingtm-reports/summary-report",
      label: dynamicLabel("Marketing TM", "Summary Reports"),
      children: <></>,
    },
  ];

  const commonTabs = [
    {
      key: "/reports/common-reports/feedback-report",
      label: dynamicLabel("", "Feedback Report"),
      children: <></>,
    },
  ];

  const items =
    roleName === "ADMIN"
      ? [...acmTabs, ...stmTabs, ...hi5AcmTabs, ...ctmTabs, ...suspendtmTabs, ...mastmTabs, ...accountManagementtmTabs, ...marketingtmTabs, ...commonTabs]
      : [
          ...(roleName === "HI5_ACM_MANAGER" ? [...hi5AcmTabs, ...commonTabs] : []),
          ...(roleName === "ACCOUNT_MANAGER_MANAGER" ? [...acmTabs, ...commonTabs] : []),
          ...(roleName === "SUCCESS_TEAM_MANAGER" ? [...stmTabs, ...commonTabs] : []),
          ...(roleName === "CALL_TEAM_MANAGER" ? [...ctmTabs, ...commonTabs] : []),
          ...(roleName === "SUSPEND_TEAM_MANAGER" ? [...suspendtmTabs, ...commonTabs] : []),
          ...(roleName === "MAS_TEAM_MANAGER" ? [...mastmTabs, ...commonTabs] : []),
          ...(roleName === "ACCOUNT_MANAGEMENT_MANAGER" ? [...accountManagementtmTabs] : []),
          ...(roleName === "MARKETING_TEAM_MANAGER" ? [...marketingtmTabs, ...commonTabs] : []),
        ];

  return (
    <Tabs activeKey={location.pathname} onChange={onTabChange} items={items} />
  );
};
