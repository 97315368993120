import { NavLink, useLocation } from "react-router-dom";
import CustomerService from "../services/CustomerService";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Image,
  message,
  Pagination,
  PaginationProps,
  Row,
  Tag,
} from "antd";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd/lib";
import { formatDateString } from "../utils";
import AmazonIcon from "../assets/amazon.svg";
import { FiMapPin } from "react-icons/fi";
import { CopyClipboard } from "../components/General/CopyClipboard";
import DashboardService from "../services/DashboardService";

const CustomerShipmentsPage = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastSortField = location.state?.sortField ?? "createDate";
  const lastSortOrder = location.state?.sortOrder ?? "desc";
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}`;
  const [filterText, setFilterText] = useState<string>(lastFilterText);
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortField, setSortField] = useState(lastSortField);
  const [sortOrder, setSortOrder] = useState(lastSortOrder);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  const getCustomerShipments = () => {
    setLoading(true);
    CustomerService.getCustomerShipments(
      `${filterText}&roleName=ACCOUNT_MANAGEMENT_SHIPMENT`
    )
      .then((response) => {
        setData(response.data.data);
        setTotalCount(response.data.totalElements);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerShipments();
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder]);

  const downloadInvoice = async (userId: number, shipmentId: number) => {
    try {
      const response = await DashboardService.getShipmentInvoiceMas(
        userId,
        shipmentId
      );
      if (response.status === 404) {
        message.warning(
          "There is no invoice for this shipment or the shipment date is more than 30 days old."
        );
      } else if (response.status !== 200) {
        message.error(
          "The invoice could not be downloaded. Please try again later."
        );
      } else {
        const blob = response.data;
        const url = window.URL.createObjectURL(blob);
        window.open(url, "_blank");
      }
    } catch (error) {
      console.error(error);
      message.error(
        "The invoice could not be downloaded. Please try again later."
      );
    }
  };

  const getCarrierTrackingLink = (carrier: string, trackingNumber: string) => {
    if (!carrier || !trackingNumber) return null;

    switch (carrier.toUpperCase()) {
      case "DHL":
        return `https://www.dhl.com/en/express/tracking.shtml?brand=DHL&AWB=${trackingNumber}`;
      case "UPS":
        return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
      case "APC":
        return `https://www.apc-pli.com/apc-tracking-v2.html?id=${trackingNumber}`;
      case "FEDEX":
        return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
      case "E_POST":
        return `https://epgtrack.com/${trackingNumber}`;
      case "ASENDIA":
        return `https://a1.asendiausa.com/tracking/?trackingnumber=${trackingNumber}`;
      default:
        return "#"; // Desteklenmeyen taşıyıcılar için geçersiz link
    }
  };

  const getAmazonTrackingLink = (amazonOrderId: string) => {
    if (!amazonOrderId) return "#";

    return `https://www.amazon.com/progress-tracker/package/ref=scr_pt_tp_t?_encoding=UTF8&from=gp&itemId=&packageIndex=0&shipmentId=Dqq4jcYl3&orderId=${amazonOrderId}`;
  };

  const columns: ColumnsType<any> = [
    {
      title: "Image",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 100,
      render: (text: any) => (
        <NavLink
          to={`https://www.amazon.com/dp/${text}`}
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            window.open(`https://www.amazon.com/dp/${text}`, "_blank");
          }}
        >
          <Image
            style={{
              border: "3px solid rgba(51,62,72,0.5)",
            }}
            height={"40px"}
            width={"40px"}
            preview={false}
            src={
              process.env.REACT_APP_API_BASE_URL ===
              "https://crm-test-api.oneamz.com/api/v1"
                ? AmazonIcon
                : `https://api.oneamz.com/product-photo/${text}?Authorization=${localStorage.getItem(
                    "externalAccessToken"
                  )}`
            }
          />
        </NavLink>
      ),
    },
    {
      title: "Order No",
      dataIndex: "orderNo",
      key: "orderNo",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      ellipsis: true,
      align: "center",
      width: 250,
      render: (text: string, record: any) => (
        <>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={record.customer.fullName} />
          </div>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={record.customer.email} />
          </span>
        </>
      ),
    },
    {
      title: "Ship to Country",
      dataIndex: "shipToCountry",
      key: "shipToCountry",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        a.shipToCountry.localeCompare(b.shipToCountry),
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) =>
        text ? (
          <NavLink
            to={`https://www.amazon.com/dp/${text}`}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              window.open(`https://www.amazon.com/dp/${text}`, "_blank");
            }}
          >
            {text}
          </NavLink>
        ) : null,
    },
    {
      title: "Total Shipping Fee",
      dataIndex: "totalShippingFee",
      key: "totalShippingFee",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) => a.totalShippingFee - b.totalShippingFee,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Seller Amazon Order Id",
      dataIndex: "sellerAmazonOrderId",
      key: "sellerAmazonOrderId",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Create Date",
      dataIndex: "createDate",
      key: "createDate",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        new Date(a.createDate).getTime() - new Date(b.createDate).getTime(),
      render: (text: any) =>
        text ? <span>{formatDateString(text)}</span> : null,
    },
    {
      title: "Latest Delivery Date",
      dataIndex: "latestDeliveryDate",
      key: "latestDeliveryDate",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        new Date(a.latestDeliveryDate).getTime() -
        new Date(b.latestDeliveryDate).getTime(),
      render: (text: any) =>
        text ? <span>{formatDateString(text)}</span> : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (text: any, record: any) => {
        if (!text) return null;
        const displayText = text.replace(/_/g, " ");
        let color = "";

        switch (text) {
          case "SHIPPED":
            color = "green";
            break;
          case "IN_TRANSIT":
            color = "blue";
            break;
          case "DELIVERED":
            color = "cyan";
            break;
          case "CANCELLED":
            color = "red";
            break;
          case "RETURNED":
            color = "purple";
            break;
          case "DESTROYED":
            color = "volcano";
            break;
          default:
            color = "geekblue";
        }

        if (text === "SHIPPED" || text === "DELIVERED") {
          const trackingUrl =
            getCarrierTrackingLink(record.carrier, record.trackingNumber) ||
            getAmazonTrackingLink(record.sellerAmazonOrderId);

          return (
            <Tag
              color={color}
              style={{
                cursor: trackingUrl !== "#" ? "pointer" : "default",
              }}
              onClick={() => {
                if (trackingUrl !== "#") {
                  window.open(trackingUrl, "_blank");
                }
              }}
            >
              <span style={{ display: "flex", alignItems: "center" }}>
                {displayText.toUpperCase()}{" "}
                <FiMapPin style={{ marginLeft: "4px" }} />
              </span>
            </Tag>
          );
        }

        return <Tag color={color}>{displayText.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Action",
      dataIndex: "status",
      key: "status",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any, record: any) =>
        text === "SHIPPED" || text === "DELIVERED" ? (
          <Button onClick={() => downloadInvoice(record.customer.id, record.id)}>Invoice</Button>
        ) : null,
    },
  ];

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Customer Shipments
            </div>
            <div className="customer-card-info">
              <b>{totalCount} </b>Shipments
            </div>
          </div>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey={(record) => record?.id}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={data}
          pagination={false}
          loading={loading}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            pageSizeOptions={["10", "20", "50", "100", "250"]}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};

export default CustomerShipmentsPage;
