import { Col, Row } from "antd";
import "./dashboard.css";
import { useState } from "react";
import { HeaderBar } from "../components/Dashboard/HeaderBar";
import { motion } from "framer-motion";
// import TotalSalesCard from "../components/Dashboard/MasDashboard/TotalSalesCard";
import TotalCustomersCard from "../components/Dashboard/MasDashboard/TotalCustomersCard";
import ShipmentStatusCard from "../components/Dashboard/MasDashboard/ShipmentStatusCard";
import NotificationsCard from "../components/Dashboard/MasDashboard/NotificationsCard";
import { TaskList } from "../components/Dashboard/TaskList";

export const AccountManagementDashboard = () => {
  const [dateRange, setDateRange] = useState<any>([]);
  const roleName = localStorage.getItem("roleName");

  return (
    <div
      style={{ background: "#F0F0F0", padding: "30px" }}
      className="acm-dashboard"
    >
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
        className="acm-dashboard"
      >
        <HeaderBar setDateRange={setDateRange} thisMonth={true} />

        <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
          {/* <Col sm={24} md={12} lg={12}>
            <TotalSalesCard roleName={roleName ?? ""} title="Total Sales" />
          </Col> */}
          <Col sm={24} md={12} lg={12}>
            <TotalCustomersCard
              roleName={roleName ?? ""}
              title="Total Customers"
              dateRange={dateRange}
            />
          </Col>
          <Col sm={24} md={12} lg={12}>
            <ShipmentStatusCard
              roleName={roleName ?? ""}
              title="Shipment Status"
            />
          </Col>
          <Col span={24}>
            <Row gutter={16}>
              <Col xs={24} xl={12}>
                <NotificationsCard
                  roleName={roleName ?? ""}
                  dateRange={dateRange}
                />
              </Col>
              <Col xs={24} xl={12}>
                <TaskList />
              </Col>
            </Row>
          </Col>
        </Row>
      </motion.div>
    </div>
  );
};
