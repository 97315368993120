import { NavLink, useParams } from "react-router-dom";
import CustomerService from "../../services/CustomerService";
import { useEffect, useState } from "react";
import { Button, Card, Image, Row, Select, Tag, InputNumber } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, DownloadOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { formatDateString } from "../../utils";
import DateRange from "../Common/DateRange";
import AmazonIcon from "../../assets/amazon.svg";
import { debounce } from "lodash";
import * as XLSX from "xlsx";

const { Option } = Select;

const CustomerProfitAndLoss = () => {
  const [customerProfitAndLoss, setCustomerProfitAndLoss] = useState<any>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredOrders, setFilteredOrders] = useState<any>([]);
  const [marketplaces, setMarketplaces] = useState<string[]>([]);
  const [marketplaceName, setMarketplaceName] = useState<string>("");
  // eslint-disable-next-line
  const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(
    null
  );
  const { id } = useParams();
  const roleName = localStorage.getItem("roleName");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const [startDate, endDate] = dateRange;

    let formattedDateRange;

    if (startDate && endDate) {
      const formattedStartDate = new Date(startDate).toISOString();
      const formattedEndDate = new Date(endDate).toISOString();
      formattedDateRange = `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    if (startDate === undefined || endDate === undefined) {
      return;
    }

    CustomerService.getMasCustomerProfitAndLoss(
      Number(id),
      `${formattedDateRange ?? ""}`
    )
      .then((response) => {
        const orders = response.data;
        setCustomerProfitAndLoss(orders);
        const uniqueMarketplaces: any = Array.from(
          new Set(orders.map((order: any) => order.marketplace))
        );
        setMarketplaces(uniqueMarketplaces);
        setFilteredOrders(orders);

        const marketplaceNameInParentheses =
          uniqueMarketplaces[0]?.match(/\(([^)]+)\)/)?.[1] ||
          uniqueMarketplaces[0];
        setMarketplaceName(marketplaceNameInParentheses);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, [dateRange, id]);

  const handleMarketplaceChange = (value: string) => {
    setSelectedMarketplace(value);
    if (value) {
      const filtered = customerProfitAndLoss.filter(
        (order: any) => order.marketplace === value
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(customerProfitAndLoss);
    }
  };

  const handleSave = debounce(
    (record: any, fieldName: string, newValue: number) => {
      const updatedOrders = filteredOrders.map((order: any) => {
        if (order.id === record.id) {
          const updatedOrder = { ...order, [fieldName]: newValue };

          // Total cost hesaplama
          updatedOrder.totalCost =
            parseFloat(updatedOrder.productCost) +
            parseFloat(updatedOrder.totalShippingFee);

          // Profit ve profit yüzdesi hesaplama
          if (
            roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
            roleName === "ACCOUNT_MANAGEMENT_MEMBER"
          ) {
            updatedOrder.estimatedProfit =
              parseFloat(updatedOrder.netRevenue || "0") -
              updatedOrder.totalCost;
            updatedOrder.estimatedProfitPercentage =
              (updatedOrder.estimatedProfit /
                parseFloat(updatedOrder.netRevenue || "1")) *
              100;
          } else {
            updatedOrder.estimatedProfit =
              parseFloat(updatedOrder.orderTotalUsd || "0") -
              updatedOrder.totalCost;
            updatedOrder.estimatedProfitPercentage =
              (updatedOrder.estimatedProfit /
                parseFloat(updatedOrder.orderTotalUsd || "1")) *
              100;
          }

          const payload = {
            itemCount: updatedOrder.itemCount,
            orderTotal: updatedOrder.orderTotal,
            orderTotalUsd: updatedOrder.orderTotalUsd || 0,
            totalCost: updatedOrder.totalCost,
            estimatedProfit: updatedOrder.estimatedProfit || 0,
            estimatedProfitPercentage:
              updatedOrder.estimatedProfitPercentage || 0,
            totalShippingFee: updatedOrder.totalShippingFee,
            productCost: updatedOrder.productCost,
            ...((roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
              roleName === "ACCOUNT_MANAGEMENT_MEMBER") && {
              netRevenue: updatedOrder.netRevenue,
            }),
          };

          CustomerService.updateMasCustomerProfitAndLoss(
            updatedOrder.id,
            payload
          ).catch((error) => {
            console.error("Update failed", error);
          });

          return updatedOrder;
        }
        return order;
      });
      setFilteredOrders(updatedOrders);
    },
    500
  );

  const columns: ColumnsType<any> = [
    {
      title: "Image",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 100,
      render: (text: any) => (
        <NavLink
          to={`https://www.amazon.com/dp/${text}`}
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            window.open(`https://www.amazon.com/dp/${text}`, "_blank");
          }}
        >
          <Image
            style={{
              border: "3px solid rgba(51,62,72,0.5)",
            }}
            height={"40px"}
            width={"40px"}
            preview={false}
            src={
              process.env.REACT_APP_API_BASE_URL ===
              "https://crm-test-api.oneamz.com/api/v1"
                ? AmazonIcon
                : `https://api.oneamz.com/product-photo/${text}?Authorization=${localStorage.getItem(
                    "externalAccessToken"
                  )}`
            }
          />
        </NavLink>
      ),
    },
    {
      title: "Amazon Order ID",
      dataIndex: "amazonOrderId",
      key: "amazonOrderId",
      ellipsis: true,
      align: "center",
      width: 250,
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) =>
        text ? (
          <NavLink
            to={`https://www.amazon.com/dp/${text}`}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              window.open(`https://www.amazon.com/dp/${text}`, "_blank");
            }}
          >
            {text}
          </NavLink>
        ) : null,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      ellipsis: true,
      align: "center",
      width: 300,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Purchase Date",
      dataIndex: "purchaseDate",
      key: "purchaseDate",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        new Date(a?.purchaseDate).getTime() -
        new Date(b?.purchaseDate).getTime(),
      render: (text: any) =>
        text ? <span>{formatDateString(text)}</span> : null,
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a?.itemCount - b?.itemCount,
      render: (text: any) => (text !== null ? <span>{text}</span> : null),
    },
    {
      title: "Order Total",
      dataIndex: "orderTotal",
      key: "orderTotal",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any, record: any) =>
        text !== null ? (
          <span>
            {`${Number(text)?.toFixed(2)}`}
            <span
              style={{ fontWeight: "600" }}
            >{` ${record?.currencyCode}`}</span>
          </span>
        ) : null,
    },
    {
      title: "Order Total - USD",
      dataIndex: "orderTotalUsd",
      key: "orderTotalUsd",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (text: any, record: any) => (
        <>
          <InputNumber
            min={0}
            defaultValue={text}
            onChange={(value) => handleSave(record, "orderTotalUsd", value!)}
          />
          <span style={{ fontWeight: "600" }}> USD</span>
        </>
      ),
    },
    ...(roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
    roleName === "ACCOUNT_MANAGEMENT_MEMBER"
      ? [
          {
            title: "Net Revenue",
            dataIndex: "netRevenue",
            key: "netRevenue",
            ellipsis: true,
            align: "center" as const,
            width: 200,
            render: (text: any, record: any) => (
              <>
                <InputNumber
                  min={0}
                  defaultValue={text}
                  onChange={(value) => handleSave(record, "netRevenue", value!)}
                />
                <span style={{ fontWeight: "600" }}> USD</span>
              </>
            ),
          },
        ]
      : []),
    {
      title: "Shipping Fee",
      dataIndex: "totalShippingFee",
      key: "totalShippingFee",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (text: any, record: any) => (
        <>
          <InputNumber
            min={0}
            defaultValue={text}
            onChange={(value) => handleSave(record, "totalShippingFee", value!)}
          />
          <span style={{ fontWeight: "600" }}> USD</span>
        </>
      ),
    },

    {
      title: "Product Cost",
      dataIndex: "productCost",
      key: "productCost",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (text: any, record: any) => (
        <>
          <InputNumber
            min={0}
            defaultValue={text}
            onChange={(value) => handleSave(record, "productCost", value!)}
          />
          <span style={{ fontWeight: "600" }}> USD</span>
        </>
      ),
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a?.totalCost - b?.totalCost,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text)?.toFixed(2)}`}
            <span style={{ fontWeight: "600" }}> USD</span>
          </span>
        ) : null,
    },
    {
      title: "Estimated Profit",
      dataIndex: "estimatedProfit",
      key: "estimatedProfit",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        a?.estimatedProfitPercentage - b?.estimatedProfitPercentage,
      render: (text: any, record: any) =>
        text !== null ? (
          <>
            <p style={{ padding: "0px", margin: "0px" }}>
              {text ? text?.toFixed(2) : null}{" "}
              <span style={{ fontWeight: "600" }}>USD</span>
            </p>
            <p style={{ padding: "0px", margin: "0px" }}>
              <b>{record?.estimatedProfitPercentage?.toFixed(2)}%</b>
            </p>
          </>
        ) : null,
    },
    {
      title: "Shipment Service",
      dataIndex: "shipmentService",
      key: "shipmentService",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.shipmentService?.localeCompare(b?.shipmentService),
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a?.orderStatus?.localeCompare(b?.orderStatus),
      render: (text: any) => {
        if (!text) return null;
        let color = "";
        switch (text) {
          case "Shipped":
            color = "green";
            break;
          case "Unshipped":
            color = "volcano";
            break;
          case "Pending":
            color = "orange";
            break;
          case "Canceled":
            color = "red";
            break;
          default:
            color = "geekblue";
        }
        return <Tag color={color}>{text?.toUpperCase()}</Tag>;
      },
    },
    {
      title: "OrderBOT Status",
      dataIndex: "orderBotStatus",
      key: "orderBotStatus",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.orderBotStatus?.localeCompare(b?.orderBotStatus),
      render: (text: any) => {
        if (!text) return null;
        let color = "";
        switch (text) {
          case "ORDERED":
            color = "green";
            break;
          case "ORDER_NOW":
            color = "volcano";
            break;
          case "NOT_READY":
            color = "red";
            break;
          default:
            color = "geekblue";
        }
        return <Tag color={color}>{text?.toUpperCase()}</Tag>;
      },
    },
  ];

  // downloadExcel function
  const downloadExcel = () => {
    const worksheetData = filteredOrders?.map((order: any) => {
      return {
        "Amazon Order ID": order?.amazonOrderId,
        Marketplace: order?.marketplace,
        ASIN: order?.asin,
        SKU: order?.sku,
        "Purchase Date": formatDateString(order?.purchaseDate),
        "Item Count": order?.itemCount,
        "Order Total": `${Number(order?.orderTotal)?.toFixed(2)} ${
          order?.currencyCode
        }`,
        "Order Total - USD": `${Number(order?.orderTotalUsd)?.toFixed(2)} USD`,
        "Net Revenue": `${Number(order?.netRevenue)?.toFixed(2)} USD`,
        "Shipping Fee": `${Number(order?.totalShippingFee)?.toFixed(2)} USD`,
        "Product Cost": `${Number(order?.productCost)?.toFixed(2)} USD`,
        "Total Cost": `${Number(order?.totalCost)?.toFixed(2)} USD`,
        "Estimated Profit": `${Number(order?.estimatedProfit)?.toFixed(2)} USD`,
        "Estimated Profit %": `${Number(
          order?.estimatedProfitPercentage
        )?.toFixed(2)}%`,
        "Shipment Service": order?.shipmentService,
        "Order Status": order?.orderStatus,
        "OrderBOT Status": order?.orderBotStatus,
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Profit-and-Loss");

    XLSX.writeFile(workbook, `Profit and Loss - ${marketplaceName}.xlsx`);
  };

  return (
    <Card
      className="card-customers"
      title={
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div className="customer-card-title">
            <Row justify={"start"} align={"middle"}>
              <Button
                type="default"
                onClick={() => navigate(-1)}
                style={{ marginRight: 16 }}
              >
                <ArrowLeftOutlined />
              </Button>
              <p style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}>
                Profit and Loss
              </p>
            </Row>
          </div>
          <div className="customer-card-info">
            <b>{filteredOrders?.length} </b>Orders
          </div>
        </div>
      }
      extra={
        <Row style={{ minWidth: 400, display: "flex", justifyContent: "end" }}>
          {marketplaces?.length > 1 && (
            <Select
              style={{ width: 200, marginRight: 10 }}
              placeholder="Select Marketplace"
              onChange={handleMarketplaceChange}
              allowClear
              size="large"
            >
              {marketplaces?.map((marketplace) => (
                <Option key={marketplace} value={marketplace}>
                  {marketplace}
                </Option>
              ))}
            </Select>
          )}
          <DateRange onChangeDateRange={setDateRange} thisMonth={true} />
          <Button
            style={{ marginLeft: 10 }}
            size={"large"}
            icon={<DownloadOutlined />}
            onClick={downloadExcel}
            type="primary"
          >
            Export
          </Button>
        </Row>
      }
      bordered={true}
      style={{ marginTop: "10px" }}
    >
      <Table
        rowKey={(record) => record?.id}
        loading={loading}
        scroll={{ x: "100%" }}
        sticky
        columns={columns}
        dataSource={filteredOrders}
        pagination={false}
      />
    </Card>
  );
};

export default CustomerProfitAndLoss;
