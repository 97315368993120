import http from "../http-common";
import {
  CountryReport,
  SuspendTypeMemberReport,
  SuspendFileStatus,
  SuspendFileStatusInput,
  SuspendFileMember,
  SuspendFileCount,
  SuspendAverageOpeningTime,
  MonthlySuspend,
  MemberMonthlySuspend,
  MemberAverageOpeningTime,
  CustomerDetailsReport,
  AmazonCallsReport,
  SuspendFileCountByInput,
  SuspendFileStatusGrandTotal,
  SuspendDaterangeReport,
  MemberTotalFiles,
  NumberOfViolationsPaidReports,
  TotalFileCountReports,
  GrandTotal,
  MemberBasedCountsReports,
} from "../types/AmazonSuspendedApplicantReports";

const AmazonSuspendedApplicantReportsService = {
  getSuspendFileCount: (startDate: string, endDate: string) =>
    http.get<SuspendFileCount[]>(
      "/amazon-suspended-applicant-reports/suspend-file-count",
      {
        params: { startDate, endDate },
      }
    ),

  getTotalFileCountReports: (startDate: string, endDate: string) =>
    http.get<TotalFileCountReports>(
      "/amazon-suspended-applicant-reports/total-file-count",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendFileCountByInput: (startDate: string, endDate: string) =>
    http.get<SuspendFileCountByInput[]>(
      "/amazon-suspended-applicant-reports/suspend-file-count-by-input",
      {
        params: { startDate, endDate },
      }
    ),

  getInputFileStatusGrandTotal: () =>
    http.get<GrandTotal[]>(
      "/amazon-suspended-applicant-reports/input-file-status/grandTotal"
    ),

  getSuspendFileCountSuspendTypes: (startDate: string, endDate: string) =>
    http.get<SuspendFileCountByInput[]>(
      "/amazon-suspended-applicant-reports/suspend-file-count-suspend-types",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendTypeFileStatusGrandTotal: () =>
    http.get<GrandTotal[]>(
      "/amazon-suspended-applicant-reports/suspend-type-file-status/grandTotal"
    ),

  getSuspendFileMembers: (startDate: string, endDate: string) =>
    http.get<SuspendFileMember[]>(
      "/amazon-suspended-applicant-reports/suspend-file-members",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberBasedContinuesStatusFileCounts: (
    startDate: string,
    endDate: string
  ) =>
    http.get<MemberBasedCountsReports[]>(
      "/amazon-suspended-applicant-reports/members-suspend-status/continues-counts",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendFileStatus: (startDate: string, endDate: string) =>
    http.get<SuspendFileStatus[]>(
      "/amazon-suspended-applicant-reports/suspend-file-status",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberBasedResolvedStatusFileCounts: (
    startDate: string,
    endDate: string
  ) =>
    http.get<MemberBasedCountsReports[]>(
      "/amazon-suspended-applicant-reports/members-resolved-status/report",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberBasedNotOpenStatusFileCounts: (
    startDate: string,
    endDate: string
  ) =>
    http.get<MemberBasedCountsReports[]>(
      "/amazon-suspended-applicant-reports/members-notopen-status/report",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendFileStatusGrandTotal: () =>
    http.get<SuspendFileStatusGrandTotal[]>(
      "/amazon-suspended-applicant-reports/suspend-file-status/grandTotal"
    ),

  getSuspendDaterangeReport: (startDate: string, endDate: string) =>
    http.get<SuspendDaterangeReport[]>(
      "/amazon-suspended-applicant-reports/suspend-daterange-report",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendFileStatusInput: (startDate: string, endDate: string) =>
    http.get<SuspendFileStatusInput[]>(
      "/amazon-suspended-applicant-reports/suspend-file-status-input",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendTypeMemberReport: (startDate: string, endDate: string) =>
    http.get<SuspendTypeMemberReport[]>(
      "/amazon-suspended-applicant-reports/suspend-type-member-report",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberTotalFiles: () =>
    http.get<MemberTotalFiles[]>(
      "/amazon-suspended-applicant-reports/member-total-files"
    ),

  getMonthlySuspend: (startDate: string, endDate: string) =>
    http.get<MonthlySuspend[]>(
      "/amazon-suspended-applicant-reports/monthly-suspend",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberMonthlySuspend: (startDate: string, endDate: string) =>
    http.get<MemberMonthlySuspend[]>(
      "/amazon-suspended-applicant-reports/member-monthly-suspend",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendAverageOpeningTime: (startDate: string, endDate: string) =>
    http.get<SuspendAverageOpeningTime[]>(
      "/amazon-suspended-applicant-reports/suspend-average-opening-time",
      {
        params: { startDate, endDate },
      }
    ),

  getMemberAverageOpeningTime: (startDate: string, endDate: string) =>
    http.get<MemberAverageOpeningTime[]>(
      "/amazon-suspended-applicant-reports/member-average-opening-time",
      {
        params: { startDate, endDate },
      }
    ),

  getCustomerDetails: (startDate: string, endDate: string) =>
    http.get<CustomerDetailsReport>(
      "/amazon-suspended-applicant-reports/customer-details",
      {
        params: { startDate, endDate },
      }
    ),

  getAmazonCalls: (startDate: string, endDate: string) =>
    http.get<AmazonCallsReport>(
      "/amazon-suspended-applicant-reports/amazon-calls",
      {
        params: { startDate, endDate },
      }
    ),

  getSuspendedCountryReports: (startDate: string, endDate: string) =>
    http.get<{ countryReports: CountryReport[] }>(
      "/amazon-suspended-applicant-reports/suspended-country",
      {
        params: { startDate, endDate },
      }
    ),

  getNumberOfViolationsPaidReports: (startDate: string, endDate: string) =>
    http.get<NumberOfViolationsPaidReports[]>(
      "/amazon-suspended-applicant-reports/violations",
      {
        params: { startDate, endDate },
      }
    ),
};

export default AmazonSuspendedApplicantReportsService;
