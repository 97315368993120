import { Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendFileCountByInput } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendedFileCountByInputReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const [grandTotalData, setGrandTotalData] = useState<any[]>([]);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendFileCountByInput(
          period.start,
          period.end
        )
      );

      const grandTotalResponse =
        await AmazonSuspendedApplicantReportsService.getInputFileStatusGrandTotal();
      const filteredGrandTotalData = grandTotalResponse.data.filter(
        (item) => item.grandTotal > 0
      );
      setGrandTotalData(filteredGrandTotalData);

      const responses = await Promise.all(promises);

      const statusFromByInput = responses.flatMap((response) =>
        response.data.map((item: SuspendFileCountByInput) => item.statusName)
      );
      const statusFromGrandTotal = filteredGrandTotalData.map(
        (item) => item.status
      );

      const allStatusNames = Array.from(
        new Set([...statusFromByInput, ...statusFromGrandTotal])
      );

      const normalizedData = periods.map((period, index) => {
        const periodData = responses[index].data;
        const periodMap = new Map(
          periodData.map((item: SuspendFileCountByInput) => [
            item.statusName,
            item.fileCount,
          ])
        );

        const row: Record<string, any> = { reportDate: period.label };
        allStatusNames.forEach((statusName: string) => {
          row[statusName] = periodMap.get(statusName) || 0;
        });
        return row;
      });

      setDataSource(normalizedData);

      const chartData = allStatusNames.map((statusName) => ({
        name: statusName,
        data: normalizedData.map((row: any) => row[statusName]),
      }));
      setChartSeries(chartData);

      // setDateLabels(reversedPeriods);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const columns: ColumnsType<SuspendFileCountByInput> = [
    {
      title: "Period",
      dataIndex: "reportDate",
      key: "reportDate",
      align: "center",
      fixed: "left",
      width: 180,
      sorter: (a: any, b: any) => a.reportDate.localeCompare(b.reportDate),
      defaultSortOrder: "descend",
      render: (reportDate: string) => <b>{reportDate ? reportDate : "N/A"}</b>,
    },
    ...Object.keys(dataSource[0] || {})
      .filter((key) => key !== "reportDate")
      .map((statusName) => ({
        title: statusName,
        dataIndex: statusName,
        key: statusName,
        align: "center" as "center",
        sorter: (a: any, b: any) => a[statusName] - b[statusName],
        width: 150,
        render: (fileCount: number) => (
          <b>{fileCount !== undefined ? fileCount : "N/A"}</b>
        ),
      })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.reportDate}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              //   className="summary-report-table"
              summary={() => {
                if (!grandTotalData || grandTotalData.length === 0) return null;
                return (
                  <Table.Summary.Row className="summary-cell">
                    <Table.Summary.Cell
                      index={0}
                      colSpan={1}
                      className="summary-cell"
                    >
                      <Tooltip title="All times">
                        <b>Grand Total *</b>
                      </Tooltip>
                    </Table.Summary.Cell>
                    {columns.map((col, idx) => {
                      if (idx === 0) return null;
                      const found = grandTotalData.find(
                        (item) => item.status === col.title
                      );
                      return (
                        <Table.Summary.Cell
                          key={col.key}
                          index={idx + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{found ? found.grandTotal : 0}</b>
                        </Table.Summary.Cell>
                      );
                    })}
                  </Table.Summary.Row>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendedFileCountByInputReport;
