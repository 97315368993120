import http from "../http-common";
import { ITeamXProfitAndLossReportResponse } from "../types/TeamXReport";

const getTeamXProfitAndLossReport = (startDate: string, endDate: string) => {
  return http.get<ITeamXProfitAndLossReportResponse>(
    `report/team-x/profit-loss?startDate=${startDate}&endDate=${endDate}`
  );
};

const TeamXReportService = {
  getTeamXProfitAndLossReport,
};

export default TeamXReportService;
