import http from "../http-common";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ICustomerRequestData } from "../types/Customer";

const customerSummary = (filteredDate: string = '') => {
  return http.get<any>(`/customer-summary${filteredDate}`);
};

const customerSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/dashboard/customer-summary${filteredDate}`);
};
const customerTotalOrderCount = (filteredDate: string = '') => {
  return http.get<any>(`/customers/sales/total/count${filteredDate}`);
};
const customerTotalOrderCountMe = (filteredDate: string = '') => {
  return http.get<any>(`/users/me/sales/total/count${filteredDate}`);
};
const customerSummaryFeedbackMe = () => {
  return http.get<any>(`/users/me/customer/feedback-avg`);
};
const customerSummaryFeedbackAll = () => {
  return http.get<any>(`/customer-all/feedback-avg`);
};

const salesSummary = (filteredDate: string = '') => {
  return http.get<any>(`/sales-summary${filteredDate}`);
}

const salesSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/me/sales-summary${filteredDate}`);
}

const salesSummaryByUser = (userId: number, filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/${userId}${filteredDate}`)
}

const phoneCallSummary = (filteredDate: string = '') => {
  return http.get<any>(`/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryDashboard = (filteredDate: string = '') => {
  return http.get<any>(`/dashboard/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryMe = (filteredDate: string = '') => {
  return http.get<any>(`/me/phone-call-summary${filteredDate}`);
}

const phoneCallSummaryByUser = (gsmPortalEmployeeId: number, filteredDate: string = '') => {
  return http.get<any>(`/phone-call-summary/${gsmPortalEmployeeId}${filteredDate}`);
};

const phoneCallSummaryByGsmPortalEmployeeId = (gsmPortalEmployeeIdId: number, filteredData: string = '') => {
  return http.post<any>(`/phone-call-summary/${gsmPortalEmployeeIdId}${filteredData}`);
}

const getSalesSummaryMas = (roleName?: string) => {
  return http.get<any>(`/mas-summary?roleName=${roleName}`);
}

const getTotalCustomersMas = (filteredDate: string = '', roleName?: string) => {
  return http.get<any>(`/mas-team/customer-dashboard-total${filteredDate}${roleName}`);
}

const getShipmentStatusMas = (roleName?: string) => {
  return http.get<any>(`/customer-shipment-status${roleName}`);
}

const getShipmentInvoiceMas = (userId: number, shipmentId: number) => {
  return http.get<any>(`/mas-team/customer-shipment/invoice/${userId}/${shipmentId}`, {
    headers: {
      Accept: "application/pdf"
    },
    responseType: "blob"
  });
}

const getSalesSummaryAcm = (filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/acm${filteredDate}`);
}

const getSalesSummaryMeAcm = (filteredDate: string = '') => {
  return http.get<any>(`/me/sales-summary/acm${filteredDate}`);
}

const getRetentionReportAcmForManager = (filteredDate: string = '') => {
  return http.get<any>(`acm/manager/retention-reports${filteredDate}`);
}

const getRetentionReportAcmForMember = (filteredDate: string = '') => {
  return http.get<any>(`acm/member/retention-reports${filteredDate}`);
}

const getTotalCustomersAcmForManager = (filteredDate: string = '') => {
  return http.get<any>(`/manager/acm${filteredDate}`);
}

const getTotalCustomersAcmForMember = (memberId: number, filteredDate: string = '') => {
  return http.get<any>(`/member/acm/${memberId}${filteredDate}`);
}

const getSalesSummaryHi5Acm = (filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/hi5acm${filteredDate}`);
}

const getSalesSummaryMeHi5Acm = (filteredDate: string = '') => {
  return http.get<any>(`/me/sales-summary/hi5acm${filteredDate}`);
}

const getSalesSummaryHi5AcmByUserId = (userId: number, filteredDate: string = '') => {
  return http.get<any>(`/sales-summary/hi5acm/${userId}${filteredDate}`);
}

const getRetentionReportForManager = (filteredDate: string = '') => {
  return http.get<any>(`/manager/retention-reports${filteredDate}`);
}

const getRetentionReportForMember = (filteredDate: string = '') => {
  return http.get<any>(`/member/retention-reports${filteredDate}`);
}

const getTotalCustomersHi5AcmForManager = (filteredDate: string = '') => {
  return http.get<any>(`/manager/hi5-acm${filteredDate}`);
}

const getTotalCustomersHi5AcmForMember = (memberId: number, filteredDate: string = '') => {
  return http.get<any>(`/member/hi5-acm/${memberId}${filteredDate}`);
}

const getTotalRetentionCustomerStmForManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/retention/manager?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalRetentionCustomerStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/retention/member?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalSoftwareSuspendedCustomerStmForManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/suspended/manager?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalSoftwareSuspendedCustomerStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/suspended/member?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalLearnerStmForManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/learner/manager?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalLearnerStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/stm-summary/learner/member?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalAdditionalReportStmForMember = (startDate: string = '', endDate: string = '', roleName: string = '') => {
  return http.get<any>(`/stm-additional/member/report?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}`);  
}

const getTotalRetentionProcessStmForManager = (startDate: string = '', endDate: string = '', roleName: string = '') => {
  return http.get<any>(`/stm/retention-summary/manager?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalRetentionProcessStmForMember = (startDate: string = '', endDate: string = '', roleName: string = '') => {
  return http.get<any>(`/stm/retention-summary/member?startDate=${startDate}&endDate=${endDate}`);
}

const getSalesSummaryStmForManager = (startDate: string = '', endDate: string = '', roleName: string = '') => {
  return http.get<any>(`/sales-summary/stm?startDate=${startDate}&endDate=${endDate}&roleName=${roleName}`);
}

const getSalesSummaryStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/me/sales-summary/stm?startDate=${startDate}&endDate=${endDate}`);
}

const getUnderSixMonthRetentionStmForManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/managers/under-six-months?startDate=${startDate}&endDate=${endDate}`);
}

const getUnderSixMonthRetentionStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/users/under-six-months?startDate=${startDate}&endDate=${endDate}`);
}

const getOverSixMonthRetentionStmForManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/managers/over-six-months?startDate=${startDate}&endDate=${endDate}`);
}

const getOverSixMonthRetentionStmForMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/users/over-six-months?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalFilesForSuspendTeamManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/manager/suspend-reports?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalFilesForSuspendTeamMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/member/suspend-reports?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalResolvedFilesForSuspendTeam = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/all/suspend-type-reports?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalInputChangeForSuspendTeamManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/manager/input-total-files?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalInputChangeForSuspendTeamMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/member/input-total-files?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalSuspendTypeChangeForSuspendTeamManager = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/manager/suspend-type-total-files?startDate=${startDate}&endDate=${endDate}`);
}

const getTotalSuspendTypeChangeForSuspendTeamMember = (startDate: string = '', endDate: string = '') => {
  return http.get<any>(`/member/suspend-type-total-files?startDate=${startDate}&endDate=${endDate}`);
}

const DashboardService = {
  customerSummary,
  customerSummaryMe,
  customerSummaryFeedbackMe,
  customerSummaryFeedbackAll,
  customerTotalOrderCount,
  customerTotalOrderCountMe,
  salesSummary,
  salesSummaryMe,
  salesSummaryByUser,
  phoneCallSummary,
  phoneCallSummaryDashboard,
  phoneCallSummaryMe,
  phoneCallSummaryByUser,
  phoneCallSummaryByGsmPortalEmployeeId,
  getSalesSummaryMas,
  getTotalCustomersMas,
  getShipmentStatusMas,
  getShipmentInvoiceMas,
  getSalesSummaryAcm,
  getSalesSummaryMeAcm,
  getRetentionReportAcmForManager,
  getRetentionReportAcmForMember,
  getTotalCustomersAcmForManager,
  getTotalCustomersAcmForMember,
  getSalesSummaryHi5Acm,
  getSalesSummaryMeHi5Acm,
  getSalesSummaryHi5AcmByUserId,
  getRetentionReportForManager,
  getRetentionReportForMember,
  getTotalCustomersHi5AcmForManager,
  getTotalCustomersHi5AcmForMember,
  getTotalRetentionCustomerStmForManager,
  getTotalRetentionCustomerStmForMember,
  getTotalSoftwareSuspendedCustomerStmForManager,
  getTotalSoftwareSuspendedCustomerStmForMember,
  getTotalLearnerStmForManager,
  getTotalLearnerStmForMember,
  getTotalRetentionProcessStmForManager,
  getTotalRetentionProcessStmForMember,
  getTotalAdditionalReportStmForMember,
  getSalesSummaryStmForManager,
  getSalesSummaryStmForMember,
  getUnderSixMonthRetentionStmForManager,
  getUnderSixMonthRetentionStmForMember,
  getOverSixMonthRetentionStmForManager,
  getOverSixMonthRetentionStmForMember,
  getTotalFilesForSuspendTeamManager,
  getTotalFilesForSuspendTeamMember,
  getTotalResolvedFilesForSuspendTeam,
  getTotalInputChangeForSuspendTeamManager,
  getTotalInputChangeForSuspendTeamMember,
  getTotalSuspendTypeChangeForSuspendTeamManager,
  getTotalSuspendTypeChangeForSuspendTeamMember
};

export default DashboardService;