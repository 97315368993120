import { Modal, Form, Select, Input, Button, Row } from "antd";
import { useEffect, useState } from "react";
import CustomerOrderService from "../../../services/CustomerOrderService";
import { openNotification } from "../../../utils";

const { Option } = Select;

interface OrderType {
  id: number;
  orderStatus: string;
  carrier?: string;
  service?: string;
  trackingNumber?: string;
}

interface AddExternalTrackingModalProps {
  visible: boolean;
  onClose: () => void;
  order: OrderType;
  onSaveSuccess?: () => void;
}

const AddExternalTrackingModal: React.FC<AddExternalTrackingModalProps> = ({
  visible,
  onClose,
  order,
  onSaveSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [selectedCarrier, setSelectedCarrier] = useState<string | null>(null);

  useEffect(() => {
    if (order) {
      form.setFieldsValue({
        carrier: order.carrier || undefined,
        service: order.service || undefined,
        trackingNumber: order.trackingNumber || "",
      });
      setSelectedCarrier(order.carrier || null);
    } else {
      form.resetFields();
      setSelectedCarrier(null);
    }
  }, [order, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const orderId = order.id;
      const orderStatus = order.orderStatus;
      const requestBody = {
        ...values,
        orderStatus,
      };
      setLoading(true);

      await CustomerOrderService.updateCustomerOrderById(orderId, requestBody);
      openNotification(
        "success",
        "Success",
        "Tracking info saved successfully."
      );

      form.resetFields();
      setSelectedCarrier(null);
      onSaveSuccess?.();
      onClose();
    } catch (error) {
      openNotification("error", "Error", "Failed to save tracking info.");
    } finally {
      setLoading(false);
    }
  };

  const serviceEnumAdminShipmentCarrier = () => {
    return [
      { id: "DHL", name: "DHL" },
      { id: "FEDEX", name: "FEDEX" },
      { id: "UPS", name: "UPS" },
      { id: "USPS", name: "USPS" },
      { id: "APC", name: "APC" },
      { id: "E_POST", name: "E-POST" },
      { id: "ASENDIA", name: "ASENDIA" },
    ];
  };

  const serviceEnumAdminShipmentServiceTypes = () => {
    return [
      {
        id: "fedex_international_economy#package",
        name: "Fedex Economy Package",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_priority#package",
        name: "FedEx Priority Package",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_priority#fedex_envelope",
        name: "FedEx Priority Envelope",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_economy#fedex_envelope",
        name: "Fedex Economy Envelope",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_connect_plus#fedex_envelope",
        name: "FedEx Connect Plus Envelope",
        carrier: "FEDEX",
      },
      {
        id: "fedex_ground_international#package",
        name: "FedEx Ground Package",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_connect#package",
        name: "FedEx International Connect",
        carrier: "FEDEX",
      },
      {
        id: "fedex_international_connect_plus#package",
        name: "FedEx Connect Plus",
        carrier: "FEDEX",
      },
      {
        id: "fedex_home_delivery#package",
        name: "FedEx Home Delivery",
        carrier: "FEDEX",
      },
      {
        id: "apc_expedited_ddp#package",
        name: "APC Expedited DDP",
        carrier: "APC",
      },
      {
        id: "apc_priority_ddp_delcon#package",
        name: "APC Priority DDP w/ Delcon",
        carrier: "APC",
      },
      {
        id: "rrd_priority_parcel_dc#package",
        name: "ePost Global",
        carrier: "E_POST",
      },
      {
        id: "ups_standard_international#package",
        name: "UPS Standard",
        carrier: "UPS",
      },
      {
        id: "ups_worldwide_expedited#package",
        name: "UPS Worldwide Expedited",
        carrier: "UPS",
      },
      {
        id: "ups_worldwide_saver#package",
        name: "UPS Worldwide Saver",
        carrier: "UPS",
      },
      {
        id: "ups_worldwide_economy#package",
        name: "UPS Worldwide Economy",
        carrier: "UPS",
      },
      {
        id: "select_direct_canada#package",
        name: "Asendia Select Direct Canada",
        carrier: "ASENDIA",
      },
    ];
  };

  const handleCarrierChange = (carrier: string) => {
    setSelectedCarrier(carrier);
    form.setFieldsValue({ service: undefined });
  };

  return (
    <Modal
      title="Add External Tracking"
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Carrier"
          name="carrier"
          rules={[{ required: true, message: "Carrier is required" }]}
        >
          <Select placeholder="Select Carrier" onChange={handleCarrierChange}>
            {serviceEnumAdminShipmentCarrier().map((carrier) => (
              <Option key={carrier.id} value={carrier.id}>
                {carrier.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Service" name="service">
          <Select placeholder="Select Service" disabled={!selectedCarrier}>
            {serviceEnumAdminShipmentServiceTypes()
              .filter((service) => service.carrier === selectedCarrier)
              .map((service) => (
                <Option key={service.id} value={service.id}>
                  {service.name}
                </Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Tracking Number"
          name="trackingNumber"
          rules={[
            { required: true, message: "Tracking number is required" },
            {
              min: 5,
              message: "Tracking number must be at least 5 characters",
            },
            {
              max: 50,
              message: "Tracking number must be at most 50 characters",
            },
          ]}
        >
          <Input placeholder="Enter tracking number" />
        </Form.Item>

        <Form.Item>
          <Row justify={"end"}>
            <Button
              type="default"
              onClick={onClose}
              style={{ marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button type="primary" onClick={handleSave} loading={loading}>
              Save
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddExternalTrackingModal;
