import { Row, Col, Modal, Table, Avatar } from "antd";
import React, { useEffect, useState } from "react";
import DashboardService from "../../../services/DashboardService";
import InfoCard from "./InfoCard";
import { getProfilePhotoPath } from "../../../utils";

interface Member {
  id: number;
  firstName: string;
  lastName: string;
}

interface Customer {
  id: number;
  fullName: string;
  email: string;
  inTransit: number;
  inTransitLast1Days: number;
  inTransitLast2Days: number;
  inTransitLast3Days: number;
  inTransitLast4Days: number;
  inTransitExpirationLastDelivery: number;
}

interface ReportItem {
  member: Member;
  inTransitTotal: number;
  inTransitLast4DaysTotal: number;
  inTransitLast3DaysTotal: number;
  inTransitLast2DaysTotal: number;
  inTransitLast1DaysTotal: number;
  inTransitExpirationLastDeliveryTotal: number;
  customerShipmentStatusList: Customer[];
}

interface ShipmentStatusCardProps {
  title: string;
  roleName: string;
}

const ShipmentStatusCard: React.FC<ShipmentStatusCardProps> = ({
  title,
  roleName,
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ReportItem[]>([]);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [customerModalVisible, setCustomerModalVisible] =
    useState<boolean>(false);
  const [modalData, setModalData] = useState<ReportItem[]>([]);
  const [customerData, setCustomerData] = useState<Customer[]>([]);

  const userId = localStorage.getItem("id");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    fetchShipmentStatus();
    setLoading(true);
    // eslint-disable-next-line
  }, []);

  const fetchShipmentStatus = async () => {
    try {
      let response;

      if (
        roleName === "MAS_TEAM_MANAGER" ||
        roleName === "MAS_TEAM_MEMBER" ||
        roleName === "ADMIN"
      ) {
        response = await DashboardService.getShipmentStatusMas(
          "?roleName=MAS_TEAM_MEMBER"
        );
      } else if (
        roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
        roleName === "ACCOUNT_MANAGEMENT_MEMBER" ||
        roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
      ) {
        response = await DashboardService.getShipmentStatusMas(
          "?roleName=ACCOUNT_MANAGEMENT_MEMBER"
        );
      }

      if (response && response.data && response.data.report) {
        const filteredData = response.data.report.filter(
          (item: ReportItem) =>
            item.inTransitLast4DaysTotal +
              item.inTransitLast3DaysTotal +
              item.inTransitLast2DaysTotal +
              item.inTransitLast1DaysTotal >
            0
        );
        setData(filteredData);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const getInTransitTotal = (): number => {
    return roleName === "MAS_TEAM_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
      ? data.reduce(
          (sum: number, item: ReportItem) => sum + item.inTransitTotal,
          0
        )
      : data.find((item: ReportItem) => item.member.id === Number(userId))
          ?.inTransitTotal || 0;
  };

  const getInTransitLast4DaysTotal = (): number => {
    return roleName === "MAS_TEAM_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
      ? data.reduce(
          (sum: number, item: ReportItem) =>
            sum +
            item.inTransitLast4DaysTotal +
            item.inTransitLast3DaysTotal +
            item.inTransitLast2DaysTotal +
            item.inTransitLast1DaysTotal,
          0
        )
      : data.find((item: ReportItem) => item.member.id === Number(userId))
      ? data.find((item: ReportItem) => item.member.id === Number(userId))!
          .inTransitLast4DaysTotal +
        data.find((item: ReportItem) => item.member.id === Number(userId))!
          .inTransitLast3DaysTotal +
        data.find((item: ReportItem) => item.member.id === Number(userId))!
          .inTransitLast2DaysTotal +
        data.find((item: ReportItem) => item.member.id === Number(userId))!
          .inTransitLast1DaysTotal
      : 0;
  };

  const getInTransitExpirationTotal = (): number => {
    return roleName === "MAS_TEAM_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
      ? data.reduce(
          (sum: number, item: ReportItem) =>
            sum + item.inTransitExpirationLastDeliveryTotal,
          0
        )
      : data.find((item: ReportItem) => item.member.id === Number(userId))
          ?.inTransitExpirationLastDeliveryTotal || 0;
  };

  const handleLast4DaysClick = () => {
    if (
      roleName === "MAS_TEAM_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
      roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
    ) {
      setModalData(data);
      setCustomerData([]);
    } else {
      const memberData = data.find((item) => item.member.id === Number(userId));
      const filteredCustomers =
        memberData?.customerShipmentStatusList.filter(
          (customer) =>
            customer.inTransitLast4Days +
              customer.inTransitLast3Days +
              customer.inTransitLast2Days +
              customer.inTransitLast1Days >
            0
        ) || [];
      setModalData([]);
      setCustomerData(filteredCustomers);
    }
    setModalVisible(true);
  };

  const handleMemberRowClick = (record: ReportItem) => {
    const filteredCustomers = record.customerShipmentStatusList.filter(
      (customer) =>
        customer.inTransitLast4Days +
          customer.inTransitLast3Days +
          customer.inTransitLast2Days +
          customer.inTransitLast1Days >
        0
    );
    setCustomerData(filteredCustomers);
    setCustomerModalVisible(true);
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          md={
            roleName === "ACCOUNT_MANAGEMENT_MANAGER"
              ? 24
              : roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
              ? 8
              : 12
          }
        >
          <InfoCard
            title="In Transit"
            description="Total"
            count={getInTransitTotal()}
            loading={loading}
          />
        </Col>
        <Col
          xs={24}
          md={
            roleName === "ACCOUNT_MANAGEMENT_MANAGER"
              ? 24
              : roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
              ? 8
              : 12
          }
        >
          <InfoCard
            title="In Transit Expiration"
            description="Total"
            count={getInTransitExpirationTotal()}
            loading={loading}
          />
        </Col>
        <Col
          xs={24}
          md={roleName === "ACCOUNT_MANAGEMENT_SHIPMENT" ? 8 : 24}
          onClick={handleLast4DaysClick}
          style={{ cursor: "pointer" }}
        >
          <InfoCard
            title="Last 4 Days"
            description="Total"
            count={getInTransitLast4DaysTotal()}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal
        title={
          roleName === "MAS_TEAM_MANAGER" ||
          roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
          roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
            ? "Member Details"
            : "Customer Details"
        }
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
      >
        {roleName === "MAS_TEAM_MANAGER" ||
        roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
        roleName === "ACCOUNT_MANAGEMENT_SHIPMENT" ? (
          <Table
            dataSource={modalData}
            columns={[
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {roleName === "MAS_TEAM_MANAGER"
                      ? "MAS Team Member"
                      : roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
                        roleName === "ACCOUNT_MANAGEMENT_SHIPMENT"
                      ? "Account Management Member"
                      : ""}
                  </p>
                ),
                dataIndex: "member",
                key: "member",
                ellipsis: true,
                width: 240,
                sorter: (a: any, b: any) =>
                  a?.member?.firstName?.localeCompare(b?.member?.firstName),
                defaultSortOrder: "ascend" as "ascend",
                render: (_, record: ReportItem) => (
                  <Row
                    align={"middle"}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "0px 0px",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: 5 }}
                      size={30}
                      src={getProfilePhotoPath(1)}
                    />
                    <b
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.member?.firstName} {record?.member?.lastName}
                    </b>
                  </Row>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 1 Days
                  </p>
                ),
                dataIndex: "inTransitLast1DaysTotal",
                key: "inTransitLast1DaysTotal",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast1DaysTotal - b?.inTransitLast1DaysTotal,
                render: (text: string, record: ReportItem) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast1DaysTotal || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 2 Days
                  </p>
                ),
                dataIndex: "inTransitLast2DaysTotal",
                key: "inTransitLast2DaysTotal",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast2DaysTotal - b?.inTransitLast2DaysTotal,
                render: (text: string, record: ReportItem) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast2DaysTotal || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 3 Days
                  </p>
                ),
                dataIndex: "inTransitLast3DaysTotal",
                key: "inTransitLast3DaysTotal",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast3DaysTotal - b?.inTransitLast3DaysTotal,
                render: (text: string, record: ReportItem) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast3DaysTotal || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 4 Days
                  </p>
                ),
                dataIndex: "inTransitLast4DaysTotal",
                key: "inTransitLast4DaysTotal",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast4DaysTotal - b?.inTransitLast4DaysTotal,
                render: (text: string, record: ReportItem) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast4DaysTotal || 0}
                    </p>
                  </div>
                ),
              },
            ]}
            rowKey={(record) => record.member.id}
            pagination={false}
            scroll={{ x: "100%", y: 185 }}
            size="small"
            className="dashboard-table"
            onRow={(record) => ({
              onClick: () => handleMemberRowClick(record),
              style: { cursor: "pointer" },
            })}
          />
        ) : (
          <Table
            dataSource={customerData}
            columns={[
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Customer
                  </p>
                ),
                dataIndex: "customer",
                key: "customer",
                ellipsis: true,
                width: 220,
                sorter: (a: any, b: any) =>
                  a?.customer?.fullName?.localeCompare(b?.customer?.fullName),
                defaultSortOrder: "ascend" as "ascend",
                render: (_, record: any) => (
                  <Row
                    align={"middle"}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      padding: "0px 0px",
                    }}
                  >
                    <Avatar
                      style={{ marginRight: 5 }}
                      size={30}
                      src={getProfilePhotoPath(1)}
                    />
                    <b
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.customer?.fullName}
                    </b>
                  </Row>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 1 Days
                  </p>
                ),
                dataIndex: "inTransitLast1Days",
                key: "inTransitLast1Days",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast1Days - b?.inTransitLast1Days,
                render: (text: string, record: any) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast1Days || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 2 Days
                  </p>
                ),
                dataIndex: "inTransitLast2Days",
                key: "inTransitLast2Days",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast2Days - b?.inTransitLast2Days,
                render: (text: string, record: any) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast2Days || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 3 Days
                  </p>
                ),
                dataIndex: "inTransitLast3Days",
                key: "inTransitLast3Days",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast3Days - b?.inTransitLast3Days,
                render: (text: string, record: any) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast3Days || 0}
                    </p>
                  </div>
                ),
              },
              {
                title: (
                  <p
                    style={{
                      margin: "0px",
                      fontWeight: "bold",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    Last 4 Days
                  </p>
                ),
                dataIndex: "inTransitLast4Days",
                key: "inTransitLast4Days",
                width: 100,
                align: "center" as "center",
                ellipsis: true,
                sorter: (a: any, b: any) =>
                  a?.inTransitLast4Days - b?.inTransitLast4Days,
                render: (text: string, record: any) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <p
                      style={{
                        margin: "0px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize:
                          windowWidth < 480
                            ? "12px"
                            : windowWidth < 768
                            ? "14px"
                            : windowWidth < 992
                            ? "12px"
                            : "14px",
                      }}
                    >
                      {record?.inTransitLast4Days || 0}
                    </p>
                  </div>
                ),
              },
            ]}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ x: "100%", y: 185 }}
            size="small"
            className="dashboard-table"
          />
        )}
      </Modal>
      <Modal
        title="Customer Details"
        open={customerModalVisible}
        onCancel={() => setCustomerModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={customerData}
          columns={[
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Customer
                </p>
              ),
              dataIndex: "customer",
              key: "customer",
              ellipsis: true,
              width: 220,
              sorter: (a: any, b: any) =>
                a?.customer?.fullName?.localeCompare(b?.customer?.fullName),
              defaultSortOrder: "ascend" as "ascend",
              render: (_, record: any) => (
                <Row
                  align={"middle"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    padding: "0px 0px",
                  }}
                >
                  <Avatar
                    style={{ marginRight: 5 }}
                    size={30}
                    src={getProfilePhotoPath(1)}
                  />
                  <b
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.customer?.fullName}
                  </b>
                </Row>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Last 1 Days
                </p>
              ),
              dataIndex: "inTransitLast1Days",
              key: "inTransitLast1Days",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.inTransitLast1Days - b?.inTransitLast1Days,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.inTransitLast1Days || 0}
                  </p>
                </div>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Last 2 Days
                </p>
              ),
              dataIndex: "inTransitLast2Days",
              key: "inTransitLast2Days",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.inTransitLast2Days - b?.inTransitLast2Days,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.inTransitLast2Days || 0}
                  </p>
                </div>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Last 3 Days
                </p>
              ),
              dataIndex: "inTransitLast3Days",
              key: "inTransitLast3Days",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.inTransitLast3Days - b?.inTransitLast3Days,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.inTransitLast3Days || 0}
                  </p>
                </div>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Last 4 Days
                </p>
              ),
              dataIndex: "inTransitLast4Days",
              key: "inTransitLast4Days",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.inTransitLast4Days - b?.inTransitLast4Days,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.inTransitLast4Days || 0}
                  </p>
                </div>
              ),
            },
          ]}
          rowKey={(record) => record.id}
          pagination={false}
          scroll={{ x: "100%", y: 185 }}
          size="small"
          className="dashboard-table"
        />
      </Modal>
    </>
  );
};

export default ShipmentStatusCard;
