import { Col, Row, Table, Tooltip } from "antd";
import type { ColumnsType } from "antd/es/table";
import React, { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { SuspendDaterangeReport } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const SuspendedTypeStatusReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse(); // Period'ları tersine çevir
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getSuspendDaterangeReport(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const allSuspendTypes = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.map(
              (item: SuspendDaterangeReport) => item.suspendType
            )
          )
        )
      );

      const normalizedData = allSuspendTypes.map((suspendType) => {
        const row: Record<string, any> = { suspendType };
        periods.forEach((period, index) => {
          const periodData = responses[index].data.find(
            (item: SuspendDaterangeReport) => item.suspendType === suspendType
          );
          row[`${period.label}-resolvedCount`] = periodData?.resolvedCount || 0;
          row[`${period.label}-notOpenedCount`] =
            periodData?.notOpenedCount || 0;
          row[`${period.label}-ongoingCount`] = periodData?.ongoingCount || 0;
          row[`${period.label}-total`] = periodData?.total || 0;
          row[`${period.label}-resolvedPercentage`] =
            periodData?.resolvedPercentage || 0;
          row[`${period.label}-notOpenedPercentage`] =
            periodData?.notOpenedPercentage || 0;
          row[`${period.label}-ongoingPercentage`] =
            periodData?.ongoingPercentage || 0;
        });
        return row;
      });

      setDataSource(normalizedData);

      // setDateLabels([...periods].reverse());
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource.length > 0) {
      // Grafik için serileri oluşturma
      const resolvedSeries = {
        name: "Çözüldü",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) => total + (row[`${period.label}-resolvedCount`] || 0),
            0
          )
        ),
      };

      const notOpenedSeries = {
        name: "Açılamadı",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) =>
              total + (row[`${period.label}-notOpenedCount`] || 0),
            0
          )
        ),
      };

      const ongoingSeries = {
        name: "Devam Ediyor",
        data: dateLabels.map((period) =>
          dataSource.reduce(
            (total, row) => total + (row[`${period.label}-ongoingCount`] || 0),
            0
          )
        ),
      };

      setChartSeries([resolvedSeries, notOpenedSeries, ongoingSeries]);
    }
  }, [dataSource, dateLabels]);

  const columns: ColumnsType<SuspendDaterangeReport> = [
    {
      title: "Suspend Türü",
      dataIndex: "suspendType",
      key: "suspendType",
      fixed: "left",
      width: 220,
      ellipsis: true,
      sorter: (a, b) => a.suspendType.localeCompare(b.suspendType),
      defaultSortOrder: "ascend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.suspendType}`}
          >
            <b>{record?.suspendType.toUpperCase()}</b>
          </div>
        );
      },
    },
    ...dateLabels.map((period) => ({
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          {period.label}
        </div>
      ),
      align: "center" as "center",
      children: [
        {
          title: "Çözüldü",
          dataIndex: `${period.label}-resolvedCount`,
          key: `${period.label}-resolvedCount`,
          align: "center",
          width: 115,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Devam Ediyor",
          dataIndex: `${period.label}-ongoingCount`,
          key: `${period.label}-ongoingCount`,
          align: "center",
          width: 115,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Açılamadı",
          dataIndex: `${period.label}-notOpenedCount`,
          key: `${period.label}-notOpenedCount`,
          align: "center",
          width: 115,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Toplam",
          dataIndex: `${period.label}-total`,
          key: `${period.label}-total`,
          align: "center",
          width: 115,
          render: (value: number) => <b>{value}</b>,
        },
        {
          title: "Çözüldü (%)",
          dataIndex: `${period.label}-resolvedPercentage`,
          key: `${period.label}-resolvedPercentage`,
          align: "center",
          width: 115,
          render: (value: number) => <b>{value ? value.toFixed(2) : "0.00"}</b>,
        },
        // {
        //   title: "Not Opened %",
        //   dataIndex: `${period.label}-notOpenedPercentage`,
        //   key: `${period.label}-notOpenedPercentage`,
        //   align: "center",
        //   width: 115,
        //   render: (value: number) => <b>{value}%</b>,
        // },
        // {
        //   title: "Ongoing %",
        //   dataIndex: `${period.label}-ongoingPercentage`,
        //   key: `${period.label}-ongoingPercentage`,
        //   align: "center",
        //   width: 115,
        //   render: (value: number) => <b>{value}%</b>,
        // },
      ],
    })),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xl={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xl={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.suspendType}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              summary={(data) => {
                if (!data || data.length === 0) return null;

                // Summary hesaplamaları
                const summaryTotals: Record<string, number> = {};

                dateLabels.forEach((period) => {
                  summaryTotals[`${period.label}-resolvedCount`] = data.reduce(
                    (sum, row) =>
                      sum + (row[`${period.label}-resolvedCount`] || 0),
                    0
                  );
                  summaryTotals[`${period.label}-notOpenedCount`] = data.reduce(
                    (sum, row) =>
                      sum + (row[`${period.label}-notOpenedCount`] || 0),
                    0
                  );
                  summaryTotals[`${period.label}-ongoingCount`] = data.reduce(
                    (sum, row) =>
                      sum + (row[`${period.label}-ongoingCount`] || 0),
                    0
                  );
                  summaryTotals[`${period.label}-total`] = data.reduce(
                    (sum, row) => sum + (row[`${period.label}-total`] || 0),
                    0
                  );
                  const resolvedPercentageValues = data.map(
                    (row) => row[`${period.label}-resolvedPercentage`] || 0
                  );
                  const resolvedPercentageSum = resolvedPercentageValues.reduce(
                    (sum, value) => sum + value,
                    0
                  );
                  summaryTotals[`${period.label}-resolvedPercentage`] =
                    resolvedPercentageValues.length > 0
                      ? resolvedPercentageSum / resolvedPercentageValues.length
                      : 0;
                });

                // Summary satırı oluşturma
                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      {/* Suspend Type için Summary Başlığı */}
                      <Table.Summary.Cell index={0} className="summary-cell">
                        <b>Total</b>
                      </Table.Summary.Cell>
                      {dateLabels.map((period, periodIndex) => (
                        <React.Fragment key={period.label}>
                          {/* Resolved Count Summary */}
                          <Table.Summary.Cell
                            key={`${period.label}-resolvedCount-summary`}
                            index={periodIndex * 4 + 1}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[`${period.label}-resolvedCount`] ||
                                0}
                            </b>
                          </Table.Summary.Cell>

                          {/* Ongoing Count Summary */}
                          <Table.Summary.Cell
                            key={`${period.label}-ongoingCount-summary`}
                            index={periodIndex * 4 + 2}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[`${period.label}-ongoingCount`] ||
                                0}
                            </b>
                          </Table.Summary.Cell>

                          {/* Not Opened Count Summary */}
                          <Table.Summary.Cell
                            key={`${period.label}-notOpenedCount-summary`}
                            index={periodIndex * 4 + 3}
                            align="center"
                            className="summary-cell"
                          >
                            <b>
                              {summaryTotals[
                                `${period.label}-notOpenedCount`
                              ] || 0}
                            </b>
                          </Table.Summary.Cell>

                          {/* Total Summary */}
                          <Table.Summary.Cell
                            key={`${period.label}-total-summary`}
                            index={periodIndex * 4 + 4}
                            align="center"
                            className="summary-cell"
                          >
                            <b>{summaryTotals[`${period.label}-total`] || 0}</b>
                          </Table.Summary.Cell>

                          {/* Resolved Percentage Average */}
                          <Table.Summary.Cell
                            key={`${period.label}-resolvedPercentage-summary`}
                            index={periodIndex * 4 + 5}
                            align="center"
                            className="summary-cell"
                          >
                            <Tooltip title={`Average: ${summaryTotals[`${period.label}-resolvedPercentage`] || "0.00"}`}><b>
                              {summaryTotals[
                                `${period.label}-resolvedPercentage`
                              ]
                                ? summaryTotals[
                                    `${period.label}-resolvedPercentage`
                                  ].toFixed(2)
                                : "0.00"}
                            </b></Tooltip>
                          </Table.Summary.Cell>
                        </React.Fragment>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default SuspendedTypeStatusReport;
