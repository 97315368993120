import http from "../http-common";

const updateCustomerOrderById = (id: number, data: any) => {
  return http.put<any>(`/customer-orders/${id}`, data);
};

const CustomerOrderService = {
  updateCustomerOrderById,
};

export default CustomerOrderService;
