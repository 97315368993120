import {
  Card,
  Col,
  Row,
  Typography,
  Table,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import DateRange from "../../Common/DateRange";
import { ReportsRouter } from "../ReportsRouter";
import TeamXReportService from "../../../services/TeamXReportService";
import * as XLSX from "xlsx";
import {
  ITeamXProfitAndLossReportResponse,
  MarketBasedReport,
} from "../../../types/TeamXReport";
import { formatMoney } from "../../../utils";

const { Title } = Typography;

export const AccountManagementProfitAndLossReport: React.FC = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const location = useLocation();

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(`${dateRange[0]}T00:00:00`, `${dateRange[1]}T23:59:59`);
    }
  }, [dateRange, location.pathname]);

  const getReport = (startDate: string, endDate: string) => {
    setLoading(true);
    TeamXReportService.getTeamXProfitAndLossReport(startDate, endDate)
      .then((response) => {
        const reportData = response.data as ITeamXProfitAndLossReportResponse;
        const formattedData = reportData.memberBasedProfitLossReportDtoList.map(
          (item) => ({
            key: item.member.id,
            memberName: `${item.member.firstName} ${item.member.lastName}`,
            orderTotalUsd: item.totalOrderTotalUsd,
            netRevenue: item.totalNetRevenue,
            shippingFee: item.totalShippingFee,
            productCost: item.totalProductCost,
            totalCost: item.totalTotalCost,
            estimatedProfit: item.totalEstimatedProfit,
            estimatedProfitRate:
              item.totalTotalCost > 0
                ? (item.totalTotalCost / item.totalNetRevenue) * 100
                : 0,
            marketplaceData: item.marketBasedReportList,
          })
        );
        setData(formattedData);
        setFilteredData(formattedData);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Member Name",
      dataIndex: "memberName",
      key: "memberName",
      fixed: "left",
      width: 250,
      ellipsis: true,
      sorter: (a, b) => a?.memberName.localeCompare(b?.memberName),
      defaultSortOrder: "ascend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.memberName}`}
          >
            <b>{record?.memberName}</b>
          </div>
        );
      },
    },
    {
      title: "Order Total (USD)",
      dataIndex: "orderTotalUsd",
      key: "orderTotalUsd",
      width: 200,
      align: "center",
      sorter: (a, b) => a.orderTotalUsd - b.orderTotalUsd,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.orderTotalUsd)}</b>
          </div>
        );
      },
    },
    {
      title: "Net Revenue",
      dataIndex: "netRevenue",
      key: "netRevenue",
      width: 200,
      align: "center",
      sorter: (a, b) => a.netRevenue - b.netRevenue,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.netRevenue)}</b>
          </div>
        );
      },
    },
    {
      title: "Shipping Fee",
      dataIndex: "shippingFee",
      key: "shippingFee",
      width: 200,
      align: "center",
      sorter: (a, b) => a.shippingFee - b.shippingFee,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.shippingFee)}</b>
          </div>
        );
      },
    },
    {
      title: "Product Cost",
      dataIndex: "productCost",
      key: "productCost",
      width: 200,
      align: "center",
      sorter: (a, b) => a.productCost - b.productCost,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.productCost)}</b>
          </div>
        );
      },
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      width: 200,
      align: "center",
      sorter: (a, b) => a.totalCost - b.totalCost,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.totalCost)}</b>
          </div>
        );
      },
    },
    {
      title: "Estimated Profit",
      dataIndex: "estimatedProfit",
      key: "estimatedProfit",
      width: 200,
      align: "center",
      sorter: (a, b) => a.estimatedProfit - b.estimatedProfit,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{formatMoney(record?.estimatedProfit)}</b>
          </div>
        );
      },
    },
    {
      title: "Estimated Profit Rate (%)",
      dataIndex: "estimatedProfitRate",
      key: "estimatedProfitRate",
      width: 210,
      align: "center",
      sorter: (a, b) => a.estimatedProfitRate - b.estimatedProfitRate,
      render: (value: number, record: any) => {
        const estimatedProfitRate =
          record.netRevenue > 0
            ? (record.estimatedProfit / record.netRevenue) * 100
            : 0;
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{estimatedProfitRate.toFixed(2)}%</b>
          </div>
        );
      },
    },
  ];

  const expandedRowRender = (record: any) => {
    const marketColumns: ColumnsType<MarketBasedReport> = [
      {
        title: "Marketplace",
        dataIndex: "marketplace",
        key: "marketplace",
        fixed: "left",
        width: 250,
        ellipsis: true,
        sorter: (a, b) => a?.marketplace.localeCompare(b?.marketplace),
        defaultSortOrder: "ascend",
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              title={`${record?.marketplace}`}
            >
              <b>{record?.marketplace}</b>
            </div>
          );
        },
      },
      {
        title: "Order Total (USD)",
        dataIndex: "orderTotalUsd",
        key: "orderTotalUsd",
        width: 200,
        align: "center",
        sorter: (a, b) => a.orderTotalUsd - b.orderTotalUsd,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.orderTotalUsd)}</b>
            </div>
          );
        },
      },
      {
        title: "Net Revenue",
        dataIndex: "netRevenue",
        key: "netRevenue",
        width: 200,
        align: "center",
        sorter: (a, b) => a.netRevenue - b.netRevenue,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.netRevenue)}</b>
            </div>
          );
        },
      },
      {
        title: "Shipping Fee",
        dataIndex: "shippingFee",
        key: "shippingFee",
        width: 200,
        align: "center",
        sorter: (a, b) => a.shippingFee - b.shippingFee,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.shippingFee)}</b>
            </div>
          );
        },
      },
      {
        title: "Product Cost",
        dataIndex: "productCost",
        key: "productCost",
        width: 200,
        align: "center",
        sorter: (a, b) => a.productCost - b.productCost,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.productCost)}</b>
            </div>
          );
        },
      },
      {
        title: "Total Cost",
        dataIndex: "totalCost",
        key: "totalCost",
        width: 200,
        align: "center",
        sorter: (a, b) => a.totalCost - b.totalCost,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.totalCost)}</b>
            </div>
          );
        },
      },
      {
        title: "Estimated Profit",
        dataIndex: "estimatedProfit",
        key: "estimatedProfit",
        width: 200,
        align: "center",
        sorter: (a, b) => a.estimatedProfit - b.estimatedProfit,
        render: (text: string, record: any) => {
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{formatMoney(record?.estimatedProfit)}</b>
            </div>
          );
        },
      },
      {
        title: "Estimated Profit Rate (%)",
        dataIndex: "estimatedProfitRate",
        key: "estimatedProfitRate",
        width: 210,
        align: "center",
        sorter: (a, b) => a.estimatedProfitRate - b.estimatedProfitRate,
        render: (value: number, record: any) => {
          const estimatedProfitRate =
            record.netRevenue > 0
              ? (record.estimatedProfit / record.netRevenue) * 100
              : 0;
          return (
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <b>{estimatedProfitRate.toFixed(2)}%</b>
            </div>
          );
        },
      },
    ];
    return (
      <Table
        columns={marketColumns}
        dataSource={record.marketplaceData}
        pagination={false}
        rowKey={(record) => record.marketplace}
        size="small"
        tableLayout="fixed"
        scroll={{ x: "max-content" }}
        className="summary-report-table"
      />
    );
  };

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) =>
        item.memberName.toLowerCase().includes(value)
      );
      setFilteredData(filtered);
    }
  };
  const downloadExcel = () => {
    if (filteredData.length === 0) {
      console.warn("No data available for export.");
      return;
    }
  
    // Ana tablo başlıkları
    const mainHeaders = [
      "Member Name",
      "Order Total (USD)",
      "Net Revenue",
      "Shipping Fee",
      "Product Cost",
      "Total Cost",
      "Estimated Profit",
      "Estimated Profit Rate (%)",
    ];
  
    // Alt tablo (marketplace) başlıkları
    const marketHeaders = [
      "Marketplace",
      "Order Total (USD)",
      "Net Revenue",
      "Shipping Fee",
      "Product Cost",
      "Total Cost",
      "Estimated Profit",
      "Estimated Profit Rate (%)",
    ];
  
    // Ana tablo verilerini biçimlendirme
    const mainData = filteredData.map((record: any) => [
      record.memberName,
      formatMoney(record.orderTotalUsd),
      formatMoney(record.netRevenue),
      formatMoney(record.shippingFee),
      formatMoney(record.productCost),
      formatMoney(record.totalCost),
      formatMoney(record.estimatedProfit),
      record.netRevenue > 0
        ? ((record.estimatedProfit / record.netRevenue) * 100).toFixed(2) + "%"
        : "0%",
    ]);
  
    // Alt tablo (marketplace) verilerini biçimlendirme
    let marketData: any[] = [];
  
    filteredData.forEach((record: any) => {
      record.marketplaceData.forEach((market: any) => {
        marketData.push([
          market.marketplace,
          formatMoney(market.orderTotalUsd),
          formatMoney(market.netRevenue),
          formatMoney(market.shippingFee),
          formatMoney(market.productCost),
          formatMoney(market.totalCost),
          formatMoney(market.estimatedProfit),
          market.netRevenue > 0
            ? ((market.estimatedProfit / market.netRevenue) * 100).toFixed(2) + "%"
            : "0%",
        ]);
      });
    });
  
    // Excel dosyası için çalışma sayfalarını oluşturma
    const workbook = XLSX.utils.book_new();
    const mainWorksheet = XLSX.utils.aoa_to_sheet([mainHeaders, ...mainData]);
    const marketWorksheet = XLSX.utils.aoa_to_sheet([marketHeaders, ...marketData]);
  
    // Çalışma sayfalarını ekleme
    XLSX.utils.book_append_sheet(workbook, mainWorksheet, "ProfitAndLossReport");
    XLSX.utils.book_append_sheet(workbook, marketWorksheet, "MarketplaceDetails");
  
    // Excel dosyasını kaydetme
    XLSX.writeFile(workbook, "ProfitAndLossReport.xlsx");
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              { title: <NavLink to="/">Home</NavLink> },
              { title: "Account Management Team Reports" },
              { title: "Profit and Loss Report" },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Account Management Team
              </div>
              <div className="customer-card-info">Profit and Loss Report</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Member Name"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "max-content" }}
          sticky
          rowKey={(record) => record.key}
          columns={columns}
          dataSource={filteredData}
          tableLayout="fixed"
          className="summary-report-table"
          expandable={{
            expandedRowRender,
          }}
          pagination={false}
          summary={(pageData) => {
            const totals = pageData.reduce(
              (acc, record) => {
                acc.orderTotalUsd += record.orderTotalUsd;
                acc.netRevenue += record.netRevenue;
                acc.shippingFee += record.shippingFee;
                acc.productCost += record.productCost;
                acc.totalCost += record.totalCost;
                acc.estimatedProfit += record.estimatedProfit;
                return acc;
              },
              {
                orderTotalUsd: 0,
                netRevenue: 0,
                shippingFee: 0,
                productCost: 0,
                totalCost: 0,
                estimatedProfit: 0,
              }
            );
            const estimatedProfitRate =
              totals.netRevenue > 0
                ? (totals.estimatedProfit / totals.netRevenue) * 100
                : 0;

            return (
              <Table.Summary fixed>
                <Table.Summary.Row className="summary-cell">
                  <Table.Summary.Cell
                    index={0}
                    colSpan={2}
                    align="left"
                    className="summary-cell"
                  >
                    <b>Total</b>
                  </Table.Summary.Cell>
                  {Object.values(totals).map((value, index) => (
                    <Table.Summary.Cell
                      key={index}
                      align="center"
                      className="summary-cell"
                      index={index + 2}
                    >
                      <b>{formatMoney(value as number)}</b>
                    </Table.Summary.Cell>
                  ))}
                  <Table.Summary.Cell
                    align="center"
                    index={7}
                    className="summary-cell"
                  >
                    <b>{estimatedProfitRate.toFixed(2)}%</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </Card>
    </>
  );
};
