import {
  Card,
  Col,
  Row,
  Typography,
  Table,
  Breadcrumb,
  Button,
  Input,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { SearchOutlined, DownloadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import React from "react";
import DateRange from "../../Common/DateRange";
import { ReportsRouter } from "../ReportsRouter";
import * as XLSX from "xlsx";
import AmazonSuspendedApplicantReportsService from "../../../services/AmazonSuspendedApplicantReportsService";

const { Title } = Typography;

export const SuspendTmNumberOfViolationsPaidReport: React.FC<any> = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<any>([]);
  const [filteredData, setFilteredData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [searchText, setSearchText] = useState<string>("");
  const location = useLocation();

  const getStatusTypeAndTitles = () => {
    if (
      location.pathname.includes(
        "/suspendtm-reports/number-of-violations-paid-report"
      )
    ) {
      return {
        roleName: "SUSPEND_TEAM",
        statusType: "AMAZON_SUSPENDED_APPLICANT_STATUS",
        columnTitle: "Input Status",
        breadcrumbTitle: "Suspend Team Reports",
        breadcrumbSubTitle: "Number of Violations Paid Report",
        cardTitle: "Suspend Team",
        cardSubTitle: "Number of Violations Paid Report",
      };
    } else
      return {
        roleName: "",
        statusType: "",
        columnTitle: "Unknown Role",
        breadcrumbTitle: "Unknown Reports",
        breadcrumbSubTitle: "Unknown Report",
        cardTitle: "Unknown Member",
        cardSubTitle: "Unknown Report",
      };
  };

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(`${dateRange[0]}T00:00:00`, `${dateRange[1]}T23:59:59`);
    }
    // eslint-disable-next-line
  }, [dateRange, location.pathname]);

  const getReport = (startDate: string, endDate: string) => {
    setLoading(true);
    AmazonSuspendedApplicantReportsService.getNumberOfViolationsPaidReports(
      startDate,
      endDate
    )
      .then((response) => {
        setData(response.data);
        setFilteredData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const {
    columnTitle,
    breadcrumbTitle,
    breadcrumbSubTitle,
    cardTitle,
    cardSubTitle,
  } = getStatusTypeAndTitles();

  const extractNumericValue = (value: string): number => {
    const match = value.match(/^(\d+)/);
    return match ? parseFloat(match[1]) : 0;
  };

  const columns: ColumnsType<any> = [
    {
      title: columnTitle,
      dataIndex: "InputStatus",
      key: "InputStatus",
      fixed: "left",
      width: 200,
      ellipsis: true,
      sorter: (a, b) => a?.InputStatus.localeCompare(b?.InputStatus),
      defaultSortOrder: "ascend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.InputStatus}`}
          >
            <b>{record?.InputStatus}</b>
          </div>
        );
      },
    },
    {
      title: "Total Amount",
      dataIndex: "TotalAmount",
      key: "TotalAmount",
      width: 200,
      align: "center" as "center",
      sorter: (a, b) =>
        extractNumericValue(a?.TotalAmount) -
        extractNumericValue(b?.TotalAmount),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <b>{record?.TotalAmount}</b>
          </div>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    if (value === "") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item: any) => {
        const InputStatus = `${item?.InputStatus}`.toLowerCase();
        return InputStatus.includes(value);
      });
      setFilteredData(filtered);
    }
  };

  const downloadExcel = () => {
    if (!data || data.length === 0) {
      console.warn("No data available to export.");
      return;
    }

    // Kolon başlıklarını belirle
    const headers = columns.map((col) => col.title as string);

    // Veriyi uygun formata çevir
    const formattedData = data.map((item: any) => ({
      "Input Status": item.InputStatus,
      "Total Amount": item.TotalAmount,
    }));

    // Tablo verilerini almak
    const ws = XLSX.utils.json_to_sheet(formattedData, { header: headers });

    // Otomatik sütun genişliği ayarla
    const columnWidths = headers.map((header) => ({
      wch: header.length + 5,
    }));
    ws["!cols"] = columnWidths;

    // Çalışma kitabı oluştur
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Violations Paid Report");

    // Excel dosyasını indir
    XLSX.writeFile(wb, `${cardSubTitle}.xlsx`);
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: breadcrumbTitle,
              },
              {
                title: breadcrumbSubTitle,
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                {cardTitle}
              </div>
              <div className="customer-card-info">{cardSubTitle}</div>
            </div>
            <Input
              className="search-input"
              addonBefore={<SearchOutlined />}
              placeholder="Search By Input Status"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
              size="large"
            />
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
            <Button
              style={{ marginLeft: "20px" }}
              size={"large"}
              icon={<DownloadOutlined />}
              onClick={downloadExcel}
              type="primary"
            >
              Export
            </Button>
          </Row>
        }
        bordered={true}
        style={{ marginTop: "30px" }}
      >
        <Table
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          rowKey={(record) => record.InputStatus}
          columns={columns}
          dataSource={filteredData}
          pagination={false}
          summary={(pageData) => {
            const totalAmountSum = pageData.reduce(
              (sum, record) => sum + (record?.TotalAmount || 0),
              0
            );

            return (
              <Table.Summary.Row className="summary-cell">
                <Table.Summary.Cell
                  index={0}
                  colSpan={1}
                  className="summary-cell"
                >
                  <b>Total</b>
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  index={1}
                  align="center"
                  className="summary-cell"
                >
                  <b>{totalAmountSum}</b>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            );
          }}
        />
      </Card>
    </>
  );
};
