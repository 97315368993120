import { Col, Row, Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import AmazonSuspendedApplicantReportsService from "../../../../services/AmazonSuspendedApplicantReportsService";
import dayjs from "dayjs";
import { MemberBasedCountsReports } from "../../../../types/AmazonSuspendedApplicantReports";
import ReusableChart from "../../CommonReport/SummaryReport/ReusableChart";

const MemberBasedResolvedStatusFileCountReport = ({
  dateRange,
  periodCount,
}: {
  dateRange: any;
  periodCount: number;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [dateLabels, setDateLabels] = useState<any[]>([]);
  const [chartSeries, setChartSeries] = useState<any[]>([]);
  const [chartHeight, setChartHeight] = useState<number>(200);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1600 && tableRef.current) {
        const tableColHeight = tableRef.current.getBoundingClientRect().height;
        setChartHeight(Math.max(200, tableColHeight - 30));
      } else {
        setChartHeight(250);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line
  }, [dataSource]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const periods = [];
      let start = dayjs(dateRange[0]);
      let end = dayjs(dateRange[1]);

      for (let i = 0; i < periodCount; i++) {
        const formattedStart = start.format("YYYY-MM-DDT00:00:00");
        const formattedEnd = end.format("YYYY-MM-DDT23:59:59");

        periods.push({
          start: formattedStart,
          end: formattedEnd,
          label: `${start.format("DD.MMM.YY")} - ${end.format("DD.MMM.YY")}`,
        });

        const diffDays = end.diff(start, "days") + 1;
        end = start.subtract(1, "days");
        start = end.subtract(diffDays - 1, "days");
      }

      const reversedPeriods = periods.reverse();
      getReports(reversedPeriods);
      setDateLabels(reversedPeriods);
    }
    // eslint-disable-next-line
  }, [dateRange, periodCount]);

  const getReports = async (periods: any[]) => {
    setLoading(true);
    try {
      const promises = periods.map((period) =>
        AmazonSuspendedApplicantReportsService.getMemberBasedResolvedStatusFileCounts(
          period.start,
          period.end
        )
      );

      const responses = await Promise.all(promises);

      const allMembers = Array.from(
        new Set(
          responses.flatMap((response) =>
            response.data.map(
              (item: MemberBasedCountsReports) => item.memberName
            )
          )
        )
      );
      const normalizedData = allMembers.map((memberName) => {
        const row: Record<string, any> = { memberName };
        periods.forEach((period, index) => {
          const periodData = responses[index].data.find(
            (item: MemberBasedCountsReports) =>
              item.memberName === memberName
          );
          row[period.label] = {
            statusCounts: periodData?.count || 0,
          };
        });
        return row;
      });

      setDataSource(normalizedData);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dataSource.length > 0 && dateLabels.length > 0) {
      const newSeries = dataSource.map((row) => ({
        name: row.memberName,
        data: dateLabels.map((period) => row[period.label]?.statusCounts || 0),
      }));

      setChartSeries(newSeries);
    } else {
      setChartSeries([]);
    }
  }, [dataSource, dateLabels]);

  const columns: ColumnsType<MemberBasedCountsReports> = [
    {
      title: "Member Name",
      dataIndex: "member",
      key: "member",
      fixed: "left",
      width: 220,
      ellipsis: true,
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.memberName}`}
          >
            <b>{record?.memberName}</b>
          </div>
        );
      },
    },
    {
      title: (
        <div
          className="column-group-title"
          style={{
            border: "3px solid #e9e9e9",
            paddingBottom: "8px",
            paddingTop: "8px",
            marginBottom: "-16px",
          }}
        >
          Çözüldü
        </div>
      ),
      children: dateLabels.map((period) => ({
        title: period.label,
        dataIndex: period.label,
        key: `${period.label}-inProgress`,
        align: "center",
        width: 180,
        render: (_: any, record: any) => (
          <b>{record[period.label]?.statusCounts || 0}</b>
        ),
      })),
    },
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} xxl={14}>
            <ReusableChart
              type="bar"
              series={chartSeries}
              categories={dateLabels.map((label) => label.label)}
              height={chartHeight}
            />
          </Col>
          <Col xs={24} xxl={10} ref={tableRef}>
            <Table
              rowKey={(record) => record?.memberName}
              loading={loading}
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              pagination={false}
              tableLayout="fixed"
              //   className="summary-report-table"
              summary={(summaryData) => {
                if (summaryData.length === 0) return null;

                const summaryTotals: Record<string, number> = {};

                dateLabels.forEach((period) => {
                  summaryTotals[period.label] = summaryData.reduce(
                    (acc, row) => acc + (row[period.label]?.statusCounts || 0),
                    0
                  );
                });

                return (
                  <Table.Summary fixed>
                    <Table.Summary.Row className="summary-cell">
                      <Table.Summary.Cell
                        index={0}
                        colSpan={1}
                        className="summary-cell"
                      >
                        <b>Total</b>
                      </Table.Summary.Cell>

                      {dateLabels.map((period, idx) => (
                        <Table.Summary.Cell
                          key={`${period.label}-inProgress`}
                          index={idx + 1}
                          align="center"
                          className="summary-cell"
                        >
                          <b>{summaryTotals[period.label]}</b>
                        </Table.Summary.Cell>
                      ))}
                    </Table.Summary.Row>
                  </Table.Summary>
                );
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default MemberBasedResolvedStatusFileCountReport;
