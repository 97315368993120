import {
    Breadcrumb,
    Button,
    Col,
    Form,
    Input,
    Modal,
    Row,
    Table,
    Typography,
  } from "antd";
  import type { ColumnsType } from "antd/es/table";
  import { motion } from "framer-motion";
  import { useEffect, useState } from "react";
  import { NavLink } from "react-router-dom";
  import SettingsRouter from "./SettingsRouter";
  import {
    IUserGroupRequestData,
    IUserGroupResponseData,
  } from "../../types/UserGroup";
  import UserGroupService from "../../services/UserGroupService";
  import ButtonGroup from "antd/es/button/button-group";
  import { openNotification } from "../../utils";
  
  const { Item } = Form;
  const { Title } = Typography;
  
  export const UserGroupList = () => {
    const [form] = Form.useForm();
    const [formEditUserGroup] = Form.useForm();
    const [isCreateModalVisible, setIsCreateModalVisible] =
      useState<boolean>(false);
    const [isEditModalVisible, setIsEditModalVisible] = useState<boolean>(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] =
      useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [newUserGroupName, setNewUserGroupName] = useState<
      string | undefined
    >(undefined);
    const [selectedUserGroup, setSelectedUserGroup] =
      useState<IUserGroupResponseData | null>(null);
    const [userGroups, setUserGroups] = useState<IUserGroupResponseData[]>([]);
  
    const showCreateModal = () => {
      setIsCreateModalVisible(true);
    };
  
    const handleCreateModalCancel = () => {
      setIsCreateModalVisible(false);
    };
  
    const showEditModal = (userGroupData: IUserGroupResponseData) => {
      setSelectedUserGroup(userGroupData);
      setNewUserGroupName(userGroupData.groupName);
      setIsEditModalVisible(true);
    };
  
    const handleEditModalCancel = () => {
      setIsEditModalVisible(false);
      setSelectedUserGroup(null);
    };
  
    const showDeleteModal = (userGroupData: IUserGroupResponseData) => {
      setSelectedUserGroup(userGroupData);
      setIsDeleteModalVisible(true);
    };
  
    const handleDeleteModalCancel = () => {
      setIsDeleteModalVisible(false);
      setSelectedUserGroup(null);
    };
  
    const postUserGroup = (data: IUserGroupRequestData) => {
      setLoading(true);
      UserGroupService.create(data)
        .then((response: any) => {
          getUserGroups();
          form.resetFields();
          openNotification("success", "Success", `Group created`);
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const handleCreate = async () => {
      try {
        const values = await form.validateFields();
        const newData: IUserGroupRequestData = {
          ...values,
        };
        postUserGroup(newData);
        setIsCreateModalVisible(false);
      } catch (error) {
        console.error("Validation failed:", error);
      }
    };
  
    const getUserGroups = () => {
      setLoading(true);
      UserGroupService.getAll()
        .then((response: any) => {
          let data = response.data;
          setUserGroups(data);
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const handleUpdateUserGroup = (id: number, name: string) => {
      setLoading(true);
      const updateData: IUserGroupRequestData = {
        id: id,
        groupName: name
      };
      UserGroupService.update(id, updateData)
        .then((response: any) => {
          openNotification("success", "Success", `Group updated`);
          handleEditModalCancel();
          formEditUserGroup.resetFields();
          getUserGroups();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const handleDeleteUserGroup = (id: number) => {
      setLoading(true);
      UserGroupService.remove(id)
        .then((response: any) => {
          openNotification("success", "Success", `Group deleted`);
          handleDeleteModalCancel();
          getUserGroups();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setLoading(false));
    };
  
    const columns: ColumnsType<IUserGroupResponseData> = [
      {
        title: "Group Name",
        dataIndex: "groupName",
        key: "groupName",
        sorter: (a, b) => a.groupName.localeCompare(b.groupName),
        render: (_, row) => <span>{row.groupName}</span>,
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        align: "center",
        width: 200,
        render: (_, record) => (
          <ButtonGroup style={{ marginLeft: "10px", justifyContent: "flex-end" }}>
            <Button type="primary" onClick={() => showEditModal(record)}>
              Edit
            </Button>
            <Button onClick={() => showDeleteModal(record)}>Delete</Button>
          </ButtonGroup>
        ),
      },
    ];
  
    useEffect(() => {
      getUserGroups();
    }, []);
  
    useEffect(() => {
      formEditUserGroup.setFieldsValue({
        selectedUserGroup: newUserGroupName,
      });
      // eslint-disable-next-line
    }, [newUserGroupName]);
  
    return (
      <motion.div
        initial={{ y: 200, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: 300, opacity: 0 }}
        transition={{
          type: "spring",
          stiffness: 260,
          damping: 40,
        }}
      >
        <div>
          <Row>
            <Col>
              <Title className="onemaz-page-title" level={3}>
                {}
              </Title>
              <Breadcrumb
                items={[
                  {
                    title: <NavLink to="/">Home</NavLink>,
                  },
                  {
                    title: "Settings",
                  },
                  {
                    title: "Groups",
                  },
                ]}
              />
            </Col>
          </Row>
          <SettingsRouter />
          <Button type="primary" onClick={showCreateModal}>
            Create Group
          </Button>
          <Row style={{ marginTop: "30px" }}>
            <Col span={24}>
              <Table
                loading={loading}
                columns={columns}
                dataSource={userGroups}
                scroll={{ x: "100%" }}
                rowKey={(record) =>
                  record.id?.toString() || `temp_key_${Math.random()}`
                }
              />
            </Col>
          </Row>
          {/* Create Modal */}
          <Modal
            title="Create New Group"
            open={isCreateModalVisible}
            onOk={handleCreate}
            onCancel={handleCreateModalCancel}
          >
            <Form form={form} layout="vertical">
              <Item
                name="groupName"
                label="Group Name"
                rules={[
                  {
                    required: true,
                    message: "Group name required",
                  },
                ]}
              >
                <Input style={{ width: "100%" }} />
              </Item>
            </Form>
          </Modal>
          {/* {/* Edit Modal */}
          <Modal
            title="Edit Group"
            open={isEditModalVisible}
            onCancel={handleEditModalCancel}
            footer={[
              <Button key="back" onClick={handleEditModalCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() => {
                  handleUpdateUserGroup(
                    selectedUserGroup?.id ?? 0,
                    newUserGroupName ?? ""
                  );
                }}
              >
                Save
              </Button>,
            ]}
          >
            <Form form={formEditUserGroup} layout="vertical">
              <Item
                name="selectedUserGroup"
                label="Edit Group Name"
                rules={[
                  {
                    required: true,
                    message: "Group name required",
                  },
                ]}
              >
                <Input
                  value={newUserGroupName}
                  onChange={(e) => setNewUserGroupName(e.target.value)}
                />
              </Item>
            </Form>
          </Modal>
          {/* Delete Modal */}
          <Modal
            title="Delete Group"
            open={isDeleteModalVisible}
            onCancel={handleDeleteModalCancel}
            footer={[
              <Button key="back" onClick={handleDeleteModalCancel}>
                Cancel
              </Button>,
              <Button
                key="submit"
                type="primary"
                onClick={() =>
                  selectedUserGroup &&
                  selectedUserGroup.id !== undefined &&
                  handleDeleteUserGroup(selectedUserGroup.id)
                }
              >
                Delete
              </Button>,
            ]}
          >
            <p>Are you sure you want to delete this group?</p>
          </Modal>
        </div>
      </motion.div>
    );
  };
  