import { NavLink, useParams } from "react-router-dom";
import CustomerService from "../../../services/CustomerService";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  Image,
  Menu,
  Modal,
  Row,
  Select,
  Tag,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Table } from "antd";
import { formatDateString, openNotification } from "../../../utils";
import AmazonIcon from "../../../assets/amazon.svg";
import { MoreOutlined } from "@ant-design/icons";
import AddExternalTrackingModal from "./AddExternalTrackingModal";
import { FiMapPin } from "react-icons/fi";
import CustomerOrderService from "../../../services/CustomerOrderService";

const { Option } = Select;
const { confirm } = Modal;

const CustomerOrders = () => {
  const [customerOrders, setCustomerOrders] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [filteredOrders, setFilteredOrders] = useState<any>([]);
  const [marketplaces, setMarketplaces] = useState<string[]>([]);
  // eslint-disable-next-line
  const [selectedMarketplace, setSelectedMarketplace] = useState<string | null>(
    null
  );
  const [showTrackingModal, setShowTrackingModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<any>();

  const { id } = useParams();
  const navigate = useNavigate();

  const getCustomerOrders = () => {
    setLoading(true);
    CustomerService.getMasCustomerOrders(Number(id))
      .then((response) => {
        const orders = response.data;
        setCustomerOrders(orders);
        const uniqueMarketplaces: any = Array.from(
          new Set(orders.map((order: any) => order.marketplace))
        );
        setMarketplaces(uniqueMarketplaces);
        setFilteredOrders(orders);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerOrders();
    // eslint-disable-next-line
  }, []);

  const handleMarketplaceChange = (value: string) => {
    setSelectedMarketplace(value);
    if (value) {
      const filtered = customerOrders.filter(
        (order: any) => order.marketplace === value
      );
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(customerOrders);
    }
  };

  const handleOpenTrackingModal = (order: any) => {
    setSelectedOrder(order);
    setShowTrackingModal(true);
  };

  const handleCloseTrackingModal = () => {
    setShowTrackingModal(false);
    setSelectedOrder(null);
  };

  const getCarrierTrackingLink = (carrier: string, trackingNumber: string) => {
    if (!carrier || !trackingNumber) return null;

    switch (carrier.toUpperCase()) {
      case "DHL":
        return `https://www.dhl.com/en/express/tracking.shtml?brand=DHL&AWB=${trackingNumber}`;
      case "UPS":
        return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
      case "APC":
        return `https://www.apc-pli.com/apc-tracking-v2.html?id=${trackingNumber}`;
      case "FEDEX":
        return `https://www.fedex.com/apps/fedextrack/?tracknumbers=${trackingNumber}`;
      case "E_POST":
        return `https://epgtrack.com/${trackingNumber}`;
      case "ASENDIA":
        return `https://a1.asendiausa.com/tracking/?trackingnumber=${trackingNumber}`;
      default:
        return "#";
    }
  };

  const getAmazonTrackingLink = (amazonOrderId: string) => {
    if (!amazonOrderId) return "#";

    return `https://www.amazon.com/progress-tracker/package/ref=scr_pt_tp_t?_encoding=UTF8&from=gp&itemId=&packageIndex=0&shipmentId=Dqq4jcYl3&orderId=${amazonOrderId}`;
  };

  const handleUpdatePurolatorShipment = (order: any) => {
    confirm({
      title: "Confirm Purolator Shipment Update",
      icon: <ExclamationCircleOutlined />,
      content:
        "This will mark the shipment as 'Delivered'. Are you sure you want to proceed?",
      okText: "Yes, Update to Delivered",
      cancelText: "No",
      onOk: async () => {
        try {
          const { id, trackingNumber, carrier, service } = order;
          const orderStatus = "Delivered";
          const requestBody = { trackingNumber, carrier, service, orderStatus };

          await CustomerOrderService.updateCustomerOrderById(id, requestBody);

          openNotification(
            "success",
            "Success",
            "The shipment has been successfully marked as 'Delivered'."
          );
          getCustomerOrders();
        } catch (error) {
          openNotification(
            "error",
            "Error",
            "Failed to mark the shipment as 'Delivered'. Please try again."
          );
        }
      },
    });
  };

  const columns: ColumnsType<any> = [
    {
      title: "Image",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 100,
      render: (text: any) => (
        <NavLink
          to={`https://www.amazon.com/dp/${text}`}
          target="_blank"
          onClick={(e) => {
            e.preventDefault();
            window.open(`https://www.amazon.com/dp/${text}`, "_blank");
          }}
        >
          <Image
            style={{
              // borderRadius: "50%",
              border: "3px solid rgba(51,62,72,0.5)",
            }}
            height={"40px"}
            width={"40px"}
            preview={false}
            src={
              process.env.REACT_APP_API_BASE_URL ===
              "https://crm-test-api.oneamz.com/api/v1"
                ? AmazonIcon
                : `https://api.oneamz.com/product-photo/${text}?Authorization=${localStorage.getItem(
                    "externalAccessToken"
                  )}`
            }
          />
        </NavLink>
      ),
    },
    {
      title: "OneAmz Order ID",
      dataIndex: "orderNo",
      key: "orderNo",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Amazon Order ID",
      dataIndex: "amazonOrderId",
      key: "amazonOrderId",
      ellipsis: true,
      align: "center",
      width: 250,
    },
    {
      title: "Marketplace",
      dataIndex: "marketplace",
      key: "marketplace",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) =>
        text ? <span>{text.replace(/\s*\(.*?\)\s*/g, "")}</span> : null,
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) =>
        text ? (
          <NavLink
            to={`https://www.amazon.com/dp/${text}`}
            target="_blank"
            onClick={(e) => {
              e.preventDefault();
              window.open(`https://www.amazon.com/dp/${text}`, "_blank");
            }}
          >
            {text}
          </NavLink>
        ) : null,
    },
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
      ellipsis: true,
      align: "center",
      width: 300,
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Purchase Date",
      dataIndex: "purchaseDate",
      key: "purchaseDate",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        new Date(a.purchaseDate).getTime() - new Date(b.purchaseDate).getTime(),
      render: (text: any) =>
        text ? <span>{formatDateString(text)}</span> : null,
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a.itemCount - b.itemCount,
      render: (text: any) => (text !== null ? <span>{text}</span> : null),
    },
    {
      title: "Order Total",
      dataIndex: "orderTotal",
      key: "orderTotal",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any, record: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span
              style={{ fontWeight: "600" }}
            >{`${record.currencyCode}`}</span>
          </span>
        ) : null,
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      ellipsis: true,
      align: "center",
      width: 150,
      render: (text: any) =>
        text !== null ? (
          <span>
            {`${Number(text).toFixed(2)} `}
            <span style={{ fontWeight: "600" }}>USD</span>
          </span>
        ) : null,
    },
    {
      title: "Estimated Profit",
      dataIndex: "estimatedProfit",
      key: "estimatedProfit",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) =>
        a.estimatedProfitPercentage - b.estimatedProfitPercentage,
      render: (text: any, record: any) =>
        text !== null ? (
          <>
            <p style={{ padding: "0px", margin: "0px" }}>
              {`${Number(text).toFixed(2)} `}
              <span style={{ fontWeight: "600" }}>USD</span>
            </p>
            <p style={{ padding: "0px", margin: "0px" }}>
              <b>{`${record.estimatedProfitPercentage}%`}</b>
            </p>
          </>
        ) : null,
    },
    {
      title: "Shipment Service",
      dataIndex: "shipmentService",
      key: "shipmentService",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a.shipmentService.localeCompare(b.shipmentService),
      render: (text: any) => (text ? <span>{text}</span> : null),
    },
    {
      title: "Latest Delivery Date",
      dataIndex: "latestDeliveryDate",
      key: "latestDeliveryDate",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (_: any, record: any) => {
        if (record.marketplace === "Mexico") {
          return (
            <div>
              {record.latestDeliveryDate ? (
                <span>{formatDateString(record.latestDeliveryDate)}</span>
              ) : null}
            </div>
          );
        }
      },
    },
    {
      title: "Order Status",
      dataIndex: "orderStatus",
      key: "orderStatus",
      ellipsis: true,
      align: "center",
      width: 150,
      sorter: (a: any, b: any) => a.orderStatus.localeCompare(b.orderStatus),
      render: (text: any) => {
        if (!text) return null;
        let color = "";
        switch (text) {
          case "Shipped":
            color = "green";
            break;
          case "Unshipped":
            color = "volcano";
            break;
          case "Pending":
            color = "orange";
            break;
          case "Canceled":
            color = "red";
            break;
          default:
            color = "geekblue";
        }
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      },
    },
    {
      title: "OrderBOT Status",
      dataIndex: "orderBotStatus",
      key: "orderBotStatus",
      ellipsis: true,
      align: "center",
      width: 200,
      sorter: (a: any, b: any) =>
        a.orderBotStatus.localeCompare(b.orderBotStatus),
      render: (text: any) => {
        if (!text) return null;
        let color = "";
        switch (text) {
          case "ORDERED":
            color = "green";
            break;
          case "ORDER_NOW":
            color = "volcano";
            break;
          case "NOT_READY":
            color = "red";
            break;
          default:
            color = "geekblue";
        }
        return <Tag color={color}>{text.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Shipment Status",
      dataIndex: "shipmentStatus",
      key: "shipmentStatus",
      ellipsis: true,
      align: "center",
      width: 200,
      render: (_: any, record: any) => {
        if (!record.trackingNumber || !record.shipmentStatus) return null;
        const trackingUrl =
          getCarrierTrackingLink(record.carrier, record.trackingNumber) ||
          getAmazonTrackingLink(record.amazonOrderId);
        return (
          <Tag
            color={"green"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (trackingUrl !== "#") {
                window.open(trackingUrl, "_blank");
              }
            }}
          >
            <span style={{ display: "flex", alignItems: "center" }}>
              {record.shipmentStatus} <FiMapPin style={{ marginLeft: "4px" }} />
            </span>
          </Tag>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: 120,
      align: "center",
      fixed: "right",
      render: (_: any, record: any) => {
        if (
          record.carrier === "Purolator" ||
          (record.marketplace === "Mexico" &&
            (record.orderStatus === "Shipped" ||
              record.orderStatus === "Unshipped"))
        ) {
          const menu = (
            <Menu style={{ textAlign: "center" }}>
              {record.marketplace === "Mexico" &&
              (record.orderStatus === "Shipped" ||
                record.orderStatus === "Unshipped") ? (
                <Menu.Item key="1">
                  <Button
                    type="primary"
                    onClick={() => handleOpenTrackingModal(record)}
                  >
                    Add External Tracking
                  </Button>
                </Menu.Item>
              ) : null}
              {record.carrier === "Purolator" ? (
                <Menu.Item key="2">
                  <Button
                    type="primary"
                    onClick={() => handleUpdatePurolatorShipment(record)}
                  >
                    Update Purolator Shipment
                  </Button>
                </Menu.Item>
              ) : null}
            </Menu>
          );

          return (
            <Row justify="center" align="middle" gutter={[0, 8]}>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button icon={<MoreOutlined />} />
              </Dropdown>
            </Row>
          );
        }
        return null;
      },
    },
  ];

  return (
    <Card
      className="card-customers"
      title={
        <div style={{ marginTop: 20, marginBottom: 20 }}>
          <div className="customer-card-title">
            <Row justify={"start"} align={"middle"}>
              <Button
                type="default"
                onClick={() => navigate(-1)}
                style={{ marginRight: 16 }}
              >
                <ArrowLeftOutlined />
              </Button>
              <p style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}>
                Orders
              </p>
            </Row>
          </div>
          <div className="customer-card-info">
            <b>{filteredOrders.length} </b>Orders
          </div>
        </div>
      }
      extra={
        <Row style={{ minWidth: 400, display: "flex", justifyContent: "end" }}>
          {marketplaces.length > 1 && (
            <Select
              style={{ width: 200 }}
              placeholder="Select Marketplace"
              onChange={handleMarketplaceChange}
              allowClear
            >
              {marketplaces.map((marketplace) => (
                <Option key={marketplace} value={marketplace}>
                  {marketplace}
                </Option>
              ))}
            </Select>
          )}
        </Row>
      }
      bordered={true}
      style={{ marginTop: "10px" }}
    >
      <Table
        rowKey={(record) => record?.id}
        loading={loading}
        scroll={{ x: "100%" }}
        sticky
        columns={columns}
        dataSource={filteredOrders}
        pagination={{ pageSize: 20 }}
      />
      {selectedOrder && (
        <AddExternalTrackingModal
          visible={showTrackingModal}
          onClose={handleCloseTrackingModal}
          order={selectedOrder}
          onSaveSuccess={getCustomerOrders}
        />
      )}
    </Card>
  );
};

export default CustomerOrders;
